import Api from '../../Api'
import ApiV2 from '../../ApiV2'
import { AmplifyWebcodeConnectSurvey } from '../../models/AmplifyWebCodeConnectSurvey'
import {
    AmplifyWebCodeSurveySection,
    SurveyErrorDetails,
    SurveySectionQuestion,
} from '../../models/AmplifyWebCodeSurveys'

export type ReceiveReferralList = {
    type: 'AMPLIFY_RECEIVE_REFERRAL_LIST'
    practice: Models.Practice
    referrals: ApiV2.Amplify.ReferralV2[]
    paginationInfo?: Api.PaginationInfo
}

export type RemoveReferral = {
    type: 'AMPLIFY_REMOVE_REFERRAL'
    practiceId: string
    referralId: string
}
export type ReceiveSubscriberList = {
    type: 'AMPLIFY_RECEIVE_SUBSCRIBER_LIST'
    practice: Models.Practice
    subscribers: Array<Api.Amplify.Subscriber>
}

export type ReceiveSubscriber = {
    type: 'AMPLIFY_RECEIVE_SUBSCRIBER'
    practice: Models.Practice
    subscriber: Api.Amplify.Subscriber
}

export type RemoveSubscriber = {
    type: 'AMPLIFY_REMOVE_SUBSCRIBER'
    practice: Models.Practice
    subscriber: Api.Amplify.Subscriber
}

export type ReceiveWebCodesV1 = {
    type: 'AMPLIFY_RECEIVE_WEB_CODES_V1'
    practiceId: string
    webCodes: Array<Api.WebCode>
}

export type ReceiveWebCodes = {
    type: 'AMPLIFY_RECEIVE_WEB_CODES'
    practiceId: string
    webCodes: Array<ApiV2.Amplify.WebCode>
}

export type ReceiveWebCode = {
    type: 'AMPLIFY_RECEIVE_WEB_CODE'
    practice: Models.Practice
    webCode: ApiV2.Amplify.WebCode
}

export type ReceiveChatAlertList = {
    type: 'AMPLIFY_RECEIVE_CHAT_ALERT_LIST'
    practiceId: string
    alerts: Api.Amplify.ChatAlert[]
}

export type ReceiveChatAlertTask = {
    type: 'AMPLIFY_RECEIVE_CHAT_ALERT_TASK'
    chatId: string
    task: Api.Amplify.ChatAlertTask
}

export type ReceiveChatAlertTasksList = {
    type: 'AMPLIFY_RECEIVE_CHAT_ALERT_TASKS_LIST'
    chatId: string
    tasks: Api.Amplify.ChatAlertTask[]
}

export type ReceiveChatAlert = {
    type: 'AMPLIFY_RECEIVE_CHAT_ALERT'
    practice: Models.Practice
    alert: Api.Amplify.ChatAlert
}

export type RemoveChatAlert = {
    type: 'AMPLIFY_REMOVE_CHAT_ALERT'
    practice: Models.Practice
    alert: Api.Amplify.ChatAlert
}

export type SetAvailableLocationsFetching = {
    type: 'SET_AMPLIFY_WEB_CODE_LOCATION_FETCHING'
    webCodeId: string
    isFetching: boolean
}

export type RecieveAmplifyWebCodeSurvey = {
    type: 'RECEIVE_AMPLIFY_WEB_CODE_SURVEY'
    webCodeId: string
    surveyId: string
    surveys: AmplifyWebCodeSurveySection[]
}

export type RecieveAmplifyWebcodeConnectSurvey = {
    type: 'RECEIVE_AMPLIFY_WEB_CODE_CONNECT_SURVEY'
    practiceId: string
    amplifyWebcodeId: string
    connectSurveyId: string
    connectSurvey: AmplifyWebcodeConnectSurvey
}

export type AmplifySetWeSurveyQuestion = {
    type: 'AMPLIFY_SET_WEB_CODE_SURVEY_QUESTION'
    webCodeId: string
    survey: AmplifyWebCodeSurveySection
    question: SurveySectionQuestion
}

export type AmplifySetSurveyErrors = {
    type: 'AMPLIFY_SET_SURVEY_ERROR_DETAILS'
    webCodeId: string
    errorDetails: SurveyErrorDetails
}

export type AmplifyClearSurveyErrors = {
    type: 'AMPLIFY_CLEAR_SURVEY_ERROR_DETAILS'
    webCodeId: string
}

export function receiveReferralList(
    practice: Models.Practice,
    referrals: ApiV2.Amplify.ReferralV2[],
    paginationInfo?: Api.PaginationInfo,
): ReceiveReferralList {
    return {
        type: 'AMPLIFY_RECEIVE_REFERRAL_LIST',
        practice,
        referrals,
        paginationInfo,
    }
}

export function removeReferral(practiceId: string, referralId: string): RemoveReferral {
    return {
        type: 'AMPLIFY_REMOVE_REFERRAL',
        practiceId,
        referralId,
    }
}

export function receiveSubscriberList(
    practice: Models.Practice,
    subscribers: Array<Api.Amplify.Subscriber>,
): ReceiveSubscriberList {
    return {
        type: 'AMPLIFY_RECEIVE_SUBSCRIBER_LIST',
        practice,
        subscribers,
    }
}

export function receiveSubscriber(practice: Models.Practice, subscriber: Api.Amplify.Subscriber): ReceiveSubscriber {
    return {
        type: 'AMPLIFY_RECEIVE_SUBSCRIBER',
        practice,
        subscriber,
    }
}

export function removeSubscriber(practice: Models.Practice, subscriber: Models.Subscriber): RemoveSubscriber {
    return {
        type: 'AMPLIFY_REMOVE_SUBSCRIBER',
        practice,
        subscriber,
    }
}

export function receiveWebCodesV1(practiceId: string, webCodes: Array<Api.WebCode>): ReceiveWebCodesV1 {
    return {
        type: 'AMPLIFY_RECEIVE_WEB_CODES_V1',
        practiceId,
        webCodes,
    }
}

export function receiveWebCodes(practiceId: string, webCodes: Array<ApiV2.Amplify.WebCode>): ReceiveWebCodes {
    return {
        type: 'AMPLIFY_RECEIVE_WEB_CODES',
        practiceId,
        webCodes,
    }
}

export function receiveWebCode(practice: Models.Practice, webCode: ApiV2.Amplify.WebCode): ReceiveWebCode {
    return {
        type: 'AMPLIFY_RECEIVE_WEB_CODE',
        practice,
        webCode,
    }
}

export function receiveChatAlertList(practiceId: string, alerts: Api.Amplify.ChatAlert[]): ReceiveChatAlertList {
    return {
        type: 'AMPLIFY_RECEIVE_CHAT_ALERT_LIST',
        practiceId,
        alerts,
    }
}

export function receiveChatAlertTask(chatId: string, task: Api.Amplify.ChatAlertTask): ReceiveChatAlertTask {
    return {
        type: 'AMPLIFY_RECEIVE_CHAT_ALERT_TASK',
        chatId,
        task,
    }
}

export function receiveChatAlertTasksList(
    chatId: string,
    tasks: Api.Amplify.ChatAlertTask[],
): ReceiveChatAlertTasksList {
    return {
        type: 'AMPLIFY_RECEIVE_CHAT_ALERT_TASKS_LIST',
        chatId,
        tasks,
    }
}

export function receiveChatAlert(practice: Models.Practice, alert: Api.Amplify.ChatAlert): ReceiveChatAlert {
    return {
        type: 'AMPLIFY_RECEIVE_CHAT_ALERT',
        practice,
        alert,
    }
}

export function removeChatAlert(practice: Models.Practice, alert: Api.Amplify.ChatAlert): RemoveChatAlert {
    return {
        type: 'AMPLIFY_REMOVE_CHAT_ALERT',
        practice,
        alert,
    }
}

export function setAvailableLocationsFetching(webCodeId: string, isFetching: boolean): SetAvailableLocationsFetching {
    return {
        type: 'SET_AMPLIFY_WEB_CODE_LOCATION_FETCHING',
        webCodeId,
        isFetching,
    }
}

export type ReceiveAmplifyWebCodeLocation = {
    type: 'RECEIVE_AMPLIFY_WEB_CODE_LOCATION'
    location: Models.AmplifyWebCodeLocation
}

export type ReceiveAmplifyWebCodeLocationsList = {
    type: 'RECEIVE_AMPLIFY_WEB_CODE_LOCATIONS_LIST'
    receiveWebCodeLocationsList: Models.AmplifyReceiveWebCodeLocationsList
}
export type AmplifySetActiveWebCode = {
    type: 'AMPLIFY_SET_ACTIVE_WEB_CODE'
    webCodeId: string
}

export function receiveAmplifyWebCodeLocationsList(
    receiveWebCodeLocationsList: Models.AmplifyReceiveWebCodeLocationsList,
): ReceiveAmplifyWebCodeLocationsList {
    return {
        type: 'RECEIVE_AMPLIFY_WEB_CODE_LOCATIONS_LIST',
        receiveWebCodeLocationsList,
    }
}

export function amplifySetActiveWebCode(webCodeId: string): AmplifySetActiveWebCode {
    return {
        type: 'AMPLIFY_SET_ACTIVE_WEB_CODE',
        webCodeId,
    }
}

export function receiveAmplifyWebCodeLocation(location: Models.AmplifyWebCodeLocation): ReceiveAmplifyWebCodeLocation {
    return {
        type: 'RECEIVE_AMPLIFY_WEB_CODE_LOCATION',
        location,
    }
}

export function receiveAmplifyWebCodeSurveys(
    webCodeId: string,
    surveyId: string,
    surveys: AmplifyWebCodeSurveySection[],
): RecieveAmplifyWebCodeSurvey {
    return {
        type: 'RECEIVE_AMPLIFY_WEB_CODE_SURVEY',
        webCodeId,
        surveyId,
        surveys,
    }
}

export function receiveAmplifyWebcodeConnectSurvey(
    practiceId: string,
    amplifyWebcodeId: string,
    connectSurveyId: string,
    connectSurvey: AmplifyWebcodeConnectSurvey,
): RecieveAmplifyWebcodeConnectSurvey {
    return {
        type: 'RECEIVE_AMPLIFY_WEB_CODE_CONNECT_SURVEY',
        practiceId,
        amplifyWebcodeId,
        connectSurveyId,
        connectSurvey,
    }
}

export function updateAmplifyWebCodeSurveyQuestion(
    webCodeId: string,
    survey: AmplifyWebCodeSurveySection,
    question: SurveySectionQuestion,
): AmplifySetWeSurveyQuestion {
    return {
        type: 'AMPLIFY_SET_WEB_CODE_SURVEY_QUESTION',
        webCodeId,
        survey,
        question,
    }
}

export function amplifySetSurveyErrors(webCodeId: string, errorDetails: SurveyErrorDetails): AmplifySetSurveyErrors {
    return {
        type: 'AMPLIFY_SET_SURVEY_ERROR_DETAILS',
        webCodeId,
        errorDetails,
    }
}

export function amplifyClearSurveyErrors(webCodeId: string): AmplifyClearSurveyErrors {
    return {
        type: 'AMPLIFY_CLEAR_SURVEY_ERROR_DETAILS',
        webCodeId,
    }
}

export function fetchReferralList(practice: Models.Practice, searchTerms: ApiV2.Amplify.ReferralSearchTerms): any {
    return async (dispatch: any) => {
        const { data: referrals, paginationInfo } = await ApiV2.Amplify.getAmplifyReferrals(practice.id, searchTerms)
        await dispatch(receiveReferralList(practice, referrals, paginationInfo))
        return referrals
    }
}

export function deleteReferral(practiceId: string, referralId: string): any {
    return async (dispatch: any) => {
        await Api.Amplify.deleteReferral(practiceId, referralId)
        await dispatch(removeReferral(practiceId, referralId))
        return referralId
    }
}

export function fetchSubscriberList(practice: Models.Practice): any {
    return async (dispatch: any) => {
        const subscribers = await Api.Amplify.listSubscribers(practice)
        await dispatch(receiveSubscriberList(practice, subscribers))
        return subscribers
    }
}

export function fetchSubscriber(practice: Models.Practice, subscriberId: string): any {
    return async (dispatch: any) => {
        const subscriber = await Api.Amplify.getSubscriber(practice, subscriberId)
        await dispatch(receiveSubscriber(practice, subscriber))
        return subscriber
    }
}

export function createSubscriber(practice: Models.Practice, newSubscriber: Api.Amplify.CreateSubscriber): any {
    return async (dispatch: any) => {
        const subscriber = await Api.Amplify.postCreateSubscriber(practice, newSubscriber)
        await dispatch(receiveSubscriber(practice, subscriber))
        return subscriber
    }
}

export function saveSubscriber(
    practice: Models.Practice,
    subscriber: Models.Subscriber,
    updates: Api.Amplify.UpdateSubscriber,
): any {
    return async (dispatch: any) => {
        const updatedSubscriber = await Api.Amplify.putUpdateSubscriber(practice, subscriber, updates)
        await dispatch(receiveSubscriber(practice, updatedSubscriber))
        return updatedSubscriber
    }
}

export function deleteSubscriber(practice: Models.Practice, subscriber: Models.Subscriber): any {
    return async (dispatch: any) => {
        await Api.Amplify.deleteSubscriber(practice, subscriber)
        await dispatch(removeSubscriber(practice, subscriber))
    }
}

export function fetchWebCodesV1(practiceId: string): any {
    return async (dispatch: any) => {
        const webCodes = await Api.Amplify.listWebCodes(practiceId)
        await dispatch(receiveWebCodesV1(practiceId, webCodes))
    }
}

export function fetchWebCodes(practiceId: string): any {
    return async (dispatch: any) => {
        const webCodes = await ApiV2.Amplify.getAmplifyWebcodes(practiceId)
        await dispatch(receiveWebCodes(practiceId, webCodes))
        await dispatch(fetchWebCodesV1(practiceId))
    }
}

export function fetchPaginatedWebCodes(practiceId: string, params: { search: string; page: number }): any {
    return async (dispatch: any) => {
        const { data, metadata } = await ApiV2.Amplify.getPaginatedAmplifyWebcodes(practiceId, params)
        await dispatch(receiveWebCodes(practiceId, data))
        return metadata
    }
}

export function createWebCode(practice: Models.Practice, url: string, practiceLocationIds: string[]): any {
    return async (dispatch: any) => {
        try {
            const amplifyWebCodeData = await ApiV2.Amplify.postAmplifyWebsite(practice.id, url, practiceLocationIds)

            await dispatch(receiveWebCode(practice, amplifyWebCodeData))
            await dispatch(amplifySetActiveWebCode(amplifyWebCodeData.id))
            await dispatch(getAmplifyWebcodeSurvey(amplifyWebCodeData.id, amplifyWebCodeData.survey.id))
            await dispatch(fetchWebCodesV1(practice.id))
            return amplifyWebCodeData
        } catch (e) {
            return e
        }
    }
}

export function saveWebCode(
    practice: Models.Practice,
    webCode: Models.WebCode,
    update: Partial<ApiV2.Amplify.UpdateWebCode>,
): any {
    return async (dispatch: any) => {
        try {
            const updated = await ApiV2.Amplify.putWebCode(webCode, update)
            await dispatch(receiveWebCode(practice, updated))
            await dispatch(fetchWebCodesV1(practice.id))
            return updated
        } catch (e) {
            return e
        }
    }
}

export function softDeleteWebCode(
    practice: Models.Practice,
    webCode: Models.WebCode,
    searchPage: number,
    searchValue: string,
): any {
    return async (dispatch: any) => {
        try {
            await ApiV2.Amplify.deleteWebCode(webCode.id)
            await dispatch(fetchWebCodesV1(practice.id))
            await dispatch(fetchPaginatedWebCodes(practice.id, { search: searchValue, page: searchPage }))
        } catch (e) {
            return e
        }
    }
}

export function saveSurvey(webCode: Models.WebCode, section: AmplifyWebCodeSurveySection): any {
    const surveyId = webCode.survey?.id
    return async (dispatch: any) => {
        try {
            const updated = await ApiV2.Amplify.putSurvey(webCode.id, surveyId, section)
            dispatch(amplifyClearSurveyErrors(webCode.id))
            return updated
        } catch (err) {
            const allErrors = {}
            err?.errors?.forEach((errorDetails: any) => {
                allErrors[errorDetails.metadata?.question_id] = {
                    details:
                        errorDetails.details === 'value is required.' ? 'Required Information' : errorDetails.details,
                    errorCode: errorDetails.errorCode,
                }
            })
            await dispatch(amplifySetSurveyErrors(webCode.id, allErrors))
            return err
        }
    }
}

export function getAmplifyWebcodeSurvey(amplifyWebCodeId: string, surveyId: string) {
    return async (dispatch: any) => {
        try {
            const surveyData = await ApiV2.Amplify.getAmplifyWebcodeSurvey(amplifyWebCodeId, surveyId)
            await dispatch(receiveAmplifyWebCodeSurveys(amplifyWebCodeId, surveyId, surveyData))
        } catch (e) {
            return e
        }
    }
}

export function fetchAmplifyWebcodeConnectSurvey(
    practiceId: string,
    amplifyWebcodeId: string,
    connectSurveyId: string,
) {
    return async (dispatch: any) => {
        try {
            const surveyData = await ApiV2.Amplify.getAmplifyWebcodeConnectSurvey(amplifyWebcodeId, connectSurveyId)
            await dispatch(
                receiveAmplifyWebcodeConnectSurvey(practiceId, amplifyWebcodeId, connectSurveyId, surveyData),
            )
        } catch (e) {
            return e
        }
    }
}

export function fetchChatAlertList(practiceId: string): any {
    return async (dispatch: any) => {
        try {
            if (practiceId) {
                const alerts = await Api.Amplify.listChatAlerts(practiceId)
                await dispatch(receiveChatAlertList(practiceId, alerts))
                return alerts
            }
            return
        } catch (e) {
            throw e
        }
    }
}

export function fetchChatAlertTasksList(practiceId: string, chatId: string): any {
    return async (dispatch: any) => {
        const tasks = await Api.Amplify.listChatAlertTasks(practiceId, chatId)
        await dispatch(receiveChatAlertTasksList(chatId, tasks))
        return tasks
    }
}

export function createChatAlert(practice: Models.Practice, newChatAlert: Api.Amplify.ChatAlertCreate): any {
    return async (dispatch: any) => {
        const chatAlert = await Api.Amplify.postChatAlertCreate(practice, newChatAlert)
        await dispatch(receiveChatAlert(practice, chatAlert))
        return chatAlert
    }
}

export function deleteChatAlert(practice: Models.Practice, chatAlert: Models.ChatAlert): any {
    return async (dispatch: any) => {
        const deleted = await Api.Amplify.deleteChatAlert(practice, chatAlert)
        await dispatch(removeChatAlert(practice, deleted))
        return deleted
    }
}

export function saveChatAlert(
    practice: Models.Practice,
    chatAlert: Models.ChatAlert,
    updates: Api.Amplify.ChatAlertUpdate,
): any {
    return async (dispatch: any) => {
        const updated = await Api.Amplify.putChatAlertUpdate(practice, chatAlert, updates)
        await dispatch(receiveChatAlert(practice, updated))
        return updated
    }
}

export function saveChatAlertTask(practiceId: string, changes: Api.Amplify.ChatAlertTaskUpdate): any {
    return async (dispatch: any) => {
        const task = await Api.Amplify.updateChatAlertTask(practiceId, changes)
        await dispatch(receiveChatAlertTask(changes.chat_id, task))
        return task
    }
}

export function saveChatAlertList(practice: Models.Practice, alerts: Api.Amplify.ChatAlertListUpdate[]): any {
    return async (dispatch: any) => {
        const updatedAlerts = await Api.Amplify.putChatAlertListUpdate(practice, alerts)
        await dispatch(receiveChatAlertList(practice.id, updatedAlerts))
        return updatedAlerts
    }
}

export function fetchAvailablePracticeSpecialties(): any {
    return async () => {
        const { data: specialties } = await Api.Practices.listAvailablePracticeSpecialties()
        return specialties
    }
}

export function searchAmplifyWebCodesLocations(
    practiceId: string,
    webCodeId: string,
    searchTerms: Api.Amplify.WebCodeLocationsSearchTerms,
): any {
    return async (dispatch: any) => {
        try {
            await dispatch(setAvailableLocationsFetching(webCodeId, true))

            const { page } = searchTerms
            const { data: locations, paginationInfo } = await Api.Amplify.getSearchLocationsByName({
                practiceId,
                webCodeId,
                searchTerms,
            })

            await dispatch(
                receiveAmplifyWebCodeLocationsList({ practiceId, webCodeId, locations, paginationInfo, page }),
            )
            return locations
        } catch (e) {
            await dispatch(setAvailableLocationsFetching(webCodeId, false))
            return e
        }
    }
}

export function saveAmplifyWebCodeLocation(updateLocation: Api.Amplify.WebCodeLocationUpdate): any {
    return async (dispatch: any) => {
        try {
            const location = await Api.Amplify.putUpdateLocation(updateLocation)
            await dispatch(receiveAmplifyWebCodeLocation(location))
            return location
        } catch (e) {
            return e
        }
    }
}

export function toggleEnableAllAmplifyWebCodeLocations(
    locationToggleEnableAll: Api.Amplify.WebCodeLocationToggleEnableAll,
    searchTerms: Api.Amplify.WebCodeLocationsSearchTerms,
): any {
    return async (dispatch: any) => {
        try {
            const { practiceId, webCodeId } = locationToggleEnableAll
            await Api.Amplify.postToggleEnableAllLocations(locationToggleEnableAll)
            const { data: locations, paginationInfo } = await Api.Amplify.getSearchLocationsByName({
                practiceId,
                webCodeId,
                searchTerms,
            })
            await dispatch(
                receiveAmplifyWebCodeLocationsList({
                    practiceId,
                    webCodeId,
                    locations,
                    paginationInfo,
                }),
            )
            return locations
        } catch (e) {
            return e
        }
    }
}
