import iassign from 'immutable-assign'
import _ from 'lodash'
import moment from 'moment'

import { SelectedPractice } from '../../models/enums'

import {
    AddLocationPaymentsTerminal,
    DeletePracticeLocation,
    FetchingAllPracticeLocations,
    PracticeCreateSteps,
    ReceiveAccount,
    ReceiveAccountStatus,
    ReceiveAvailability,
    ReceiveCreatedPracticeLocation,
    ReceiveLocationConnectPaymentInfoData,
    ReceiveLocationMilestone,
    ReceiveLocationPaymentsInfo,
    ReceiveLocationSchedulingData,
    ReceiveLocationsSchedulingData,
    ReceiveLocationStatementDescriptor,
    ReceiveLocationStripeAccountId,
    ReceiveLocationVyneProducts,
    ReceivePermissionList,
    ReceivePractice,
    ReceivePracticeAgent,
    ReceivePracticeAgents,
    ReceivePracticeHasEmailNotificationSubscribers,
    ReceivePracticeLocation,
    ReceivePracticeLocationProcedureProviders,
    ReceivePracticeLocationProcedures,
    ReceivePracticeLocationProducts,
    ReceivePracticeLocations,
    ReceivePracticeLocationsAgentModules,
    ReceivePracticeLogo,
    ReceivePracticeLogoBgColor,
    ReceivePracticeProducts,
    ReceivePractices,
    ReceivePracticeUserRoles,
    ReceivePracticeWithLocations,
    ReceiveProcedureAvailability,
    ReceiveUnboundPracticeAgent,
    ReceiveUserPhoneNumberBlackList,
    RemoveLocationPaymentsTerminal,
    SavingPracticeLocationProducts,
    SavingPracticeProducts,
    SetCreateStep,
    SetIsSavingPracticeStaff,
    SetLocationSurveyStatusToCompleted,
    SetSelectedPractice,
    UpdateAllLocationsStripeAccountId,
    UpdateLocationCompletionStatus,
    UpdatePaymentRatesStatus,
    UpdatePracticeSearchTerms,
    UpdateSingleLocationStripeAccountId,
} from './actions'

export type PracticesState = {
    practices: { [key: string]: Models.Practice }
    permissionList: Array<Models.Permission>
    createState: {
        step: PracticeCreateSteps
        name?: string
    }
    practicesPaginationInfo?: Models.PaginationInfo
    selectedPractice?: SelectedPractice
    searchTerms: Models.SearchTerms
    userRoles: Models.PracticeUserRole[]
    isSavingPracticeProducts: boolean
    isSavingPracticeLocationProducts: boolean
    practiceLocationsAgentModules: { [key: string]: ModelsV2.Practice.PracticeSchedulingLocationsData[] }
    isSavingPracticeStaff: { [key: string]: boolean }
    isFetchingAllPracticeLocations: { [key: string]: boolean }
}

const initialState = (): PracticesState => ({
    practices: {},
    permissionList: [],
    createState: {
        step: null,
    },
    practicesPaginationInfo: {
        allPages: 1,
        allRows: 0,
    },
    searchTerms: {
        searchKey: '',
        page: 1,
    },
    userRoles: [],
    isSavingPracticeProducts: false,
    isSavingPracticeLocationProducts: false,
    practiceLocationsAgentModules: {},
    isSavingPracticeStaff: {},
    isFetchingAllPracticeLocations: {},
})

type PracticesAction =
    | ReceivePractice
    | ReceivePractices
    | ReceiveAccount
    | ReceivePracticeProducts
    | SetCreateStep
    | ReceivePermissionList
    | ReceiveAccountStatus
    | UpdatePracticeSearchTerms
    | ReceivePracticeLocations
    | SetLocationSurveyStatusToCompleted
    | ReceivePracticeWithLocations
    | ReceivePracticeLocation
    | ReceiveCreatedPracticeLocation
    | DeletePracticeLocation
    | ReceivePracticeAgent
    | ReceivePracticeAgents
    | ReceiveUnboundPracticeAgent
    | SetSelectedPractice
    | ReceivePracticeUserRoles
    | ReceivePracticeLogo
    | ReceivePracticeLocationProcedures
    | ReceiveProcedureAvailability
    | ReceiveAvailability
    | ReceivePracticeHasEmailNotificationSubscribers
    | ReceiveLocationSchedulingData
    | ReceiveLocationConnectPaymentInfoData
    | ReceiveLocationsSchedulingData
    | ReceiveLocationPaymentsInfo
    | RemoveLocationPaymentsTerminal
    | AddLocationPaymentsTerminal
    | ReceiveUserPhoneNumberBlackList
    | ReceivePracticeLogoBgColor
    | ReceiveLocationStripeAccountId
    | ReceiveLocationStatementDescriptor
    | UpdateAllLocationsStripeAccountId
    | UpdateSingleLocationStripeAccountId
    | ReceivePracticeLocationProcedureProviders
    | SavingPracticeProducts
    | SavingPracticeLocationProducts
    | ReceivePracticeLocationProducts
    | UpdateLocationCompletionStatus
    | ReceiveLocationMilestone
    | UpdatePaymentRatesStatus
    | ReceivePracticeLocationsAgentModules
    | SetIsSavingPracticeStaff
    | FetchingAllPracticeLocations
    | ReceiveLocationVyneProducts

function mapAddress(address: Api.Address) {
    return {
        id: address.id,
        type: address.type,
        street: address.street,
        street2: address.street2,
        unit: address.unit,
        city: address.city,
        state: address.state,
        zip: address.zip,
        countryCodeIsoAlpha2: address.country_code_iso_alpha_2,
        lat: address.lat,
        lng: address.lng,
    }
}

function mapUserGroups(group: Api.PracticeLocationUserGroup): Models.PracticeLocationUserGroup {
    return {
        id: group.id,
        active: group.active,
        name: group.name,
        users: group.users,
        defaultSortOrder: group.default_sort_order,
        groupName: group.group_name,
        members: group.members,
        userIds: group.user_ids,
        isGeneratedNoneGroup: group.is_generated_none_group,
    }
}

export function mapLocationForPractice(location: Api.PracticeLocationOnPractice): Models.PracticeLocation {
    return {
        id: location.id,
        practiceId: location.practice_id,
        addressId: location.address_id,
        name: location.name,
        externalSystemId: location.external_system_id,
        description: location.description,
        active: location.active,
        agentId: location.agent_id,
        address: location.address && mapAddress(location.address),
        phoneNumber: location.phone_number,
        userGroups: location.user_groups?.map(mapUserGroups),
        jarvisEnabled: location.jarvis_enabled,
        jarvisLocationId: location.jarvis_location_id,
        jarvisLocationLocationId: location.jarvis_location_location_id,
        timezone: location.timezone,
        practiceSpecialtyType: location.practice_specialty_type,
        survey: location.survey,
        yext_enabled: location.yext_enabled,
        isVyneCreated: location.is_vyne_created,
        facilityId: location.facility_id,
        isVyneControlled: location.is_vyne_controlled,
        products: location.products?.map(p => {
            return {
                active: p.active,
                practice_location_id: p.practice_location_id,
                product_id: p.product_id,
                product: {
                    id: p.id,
                    value: p.value,
                    display_name: p.display_name,
                    default_paid_modules: p.default_paid_modules,
                },
            }
        }),
        isOnlineSchedulingWritebackEnabled: location.is_online_scheduling_writeback_enabled,
        onlineSchedulingWritebackUpdated: location.online_scheduling_writeback_updated,
    }
}

export function mapLocation(location: Api.PracticeLocation): Models.PracticeLocation {
    return {
        id: location.id,
        practiceId: location.practice_id,
        addressId: location.address_id,
        name: location.name,
        externalSystemId: location.external_system_id,
        description: location.description,
        active: location.active,
        agentId: location.agent_id,
        address: location.address && mapAddress(location.address),
        phoneNumber: location.phone_number,
        userGroups: location.user_groups?.map(mapUserGroups),
        jarvisEnabled: location.jarvis_enabled,
        jarvisLocationId: location.jarvis_location_id,
        jarvisLocationLocationId: location.jarvis_location_location_id,
        timezone: location.timezone,
        practiceSpecialtyType: location.practice_specialty_type,
        survey: location.survey,
        yext_enabled: location.yext_enabled,
        isVyneCreated: location.is_vyne_created,
        facilityId: location.facility_id,
        isVyneControlled: location.is_vyne_controlled,
        products: location.products?.map(p => {
            return {
                active: p.active,
                practice_location_id: p.practice_location_id,
                product_id: p.product_id,
                product: {
                    id: p.product.id,
                    value: p.product.value,
                    display_name: p.product.display_name,
                    default_paid_modules: p.product.default_paid_modules,
                },
            }
        }),
        isOnlineSchedulingWritebackEnabled: location.is_online_scheduling_writeback_enabled,
        onlineSchedulingWritebackUpdated: location.online_scheduling_writeback_updated,
    }
}

export function mapCreatedLocation(
    location: ModelsV2.Practice.CreatePracticeLocationResponse,
): Models.PracticeLocation {
    return {
        id: location.id,
        created: new Date(),
        updated: new Date(),
        practiceId: location.practice_id,
        addressId: location.address.id,
        name: location.name,
        externalSystemId: null,
        description: location.description,
        active: location.active,
        agentId: null,
        address:
            location.address &&
            mapAddress({ ...location.address, created: new Date().toDateString(), updated: new Date().toDateString() }),
        phoneNumber: location.phone_number,
        userGroups: undefined,
        jarvisEnabled: undefined,
        jarvisLocationId: undefined,
        jarvisLocationLocationId: undefined,
        timezone: location.timezone,
        practiceSpecialtyType: location.practice_specialty_type,
        survey: { status: 'not_completed', id: location.survey_id },
        facilityId: location.facility_id,
        products: location.products.map(p => {
            return {
                active: p.active,
                practice_location_id: p.practice_location_id,
                product_id: p.product_id,
                product: {
                    id: p.product_id,
                    value: '',
                    display_name: '',
                    default_paid_modules: [],
                },
            }
        }),
        //isTrainingComplete: false,
    }
}

function mapProduct(product: Api.Product): Models.Product {
    return {
        id: product.id,
        value: product.value,
        displayName: product.display_name,
        active: product.active,
    }
}

function mapProductForLocation(product: Api.PracticeLocationProductOnPractice): Models.PracticeLocationProduct {
    return {
        product_id: product.product_id,
        active: product.active,
        practice_location_id: product.practice_location_id,
        product: {
            display_name: product.display_name,
            value: product.value,
            id: product.id,
            default_paid_modules: product.default_paid_modules,
        },
    }
}

function mapPermission(permission: Api.Permission): Models.Permission {
    return {
        id: permission.id,
        type: permission.type,
        displayName: permission.display_name,
        isPublic: permission.is_public,
    }
}

function mapPracticeLocationPermissions(
    permissions: Api.AccountPracticeLocationPermissions,
): Models.AccountPracticeLocationPermissions {
    return {
        emailNotifications: permissions && permissions.email_notifications,
        smsNotifications: permissions && permissions.sms_notifications,
    }
}

export function mapPracticeLocation(practiceLocation: Api.AccountPracticeLocation): Models.AccountPracticeLocation {
    return {
        id: practiceLocation.id,
        accountId: practiceLocation.account_id,
        practiceLocationId: practiceLocation.practice_location_id,
        status: practiceLocation.status,
        permissions: mapPracticeLocationPermissions(practiceLocation.permissions),
        created: practiceLocation.created,
        updated: practiceLocation.updated,
        practiceId: practiceLocation.practice_id,
        addressId: practiceLocation.address_id,
        name: practiceLocation.name,
        externalSystemId: practiceLocation.external_system_id,
        description: practiceLocation.description,
        active: practiceLocation.active,
        agentId: practiceLocation.agent_id,
    }
}

export function mapAccount(account: Api.Account): Models.Account {
    return {
        id: account.id,
        username: account.username,
        firstName: account.first_name,
        lastName: account.last_name,
        name: `${account.first_name} ${account.last_name}`,
        type: {
            id: account.type.id,
            value: account.type.value,
            displayName: account.type.display_name,
        },
        permissions: account.permissions.map(p => mapPermission(p)),
        medicalRole: account.medical_role,
        created: new Date(account.created),
        accepted: account.accepted,
        active: account.active,
        role: account.role,
        accountStatus: account.account_status,
        practiceLocations: account.practice_locations && account.practice_locations.map(p => mapPracticeLocation(p)),
        daysUntilDeletion: account.days_until_deletion && account.days_until_deletion,
        phoneNumbers: account.phone_numbers,
        emails: account.emails,
        lockedUntil: account.locked_until,
        locked: account.locked,
        yextUserId: account.yext_user_id,
    }
}

export function mapAgent(agent: Api.PracticeAgent): Models.PracticeAgent {
    return {
        id: agent.id,
        activationCode: agent.activationCode,
        active: agent.active,
        activatedAt: agent.activatedAt ? new Date(agent.activatedAt) : undefined,
        agentIdentifier: agent.agentIdentifier,
        createdAt: new Date(agent.createdAt),
        email: agent.email,
        externalCustomerId: agent.externalCustomerId,
        futureDays: agent.futureDays,
        integrationTypeString: agent.integrationTypeString,
        name: agent.name,
        phone: agent.phone,
        practiceId: agent.practiceId,
        locationId: agent.locationId,
        sendData: agent.sendData,
        sharedKey: agent.sharedKey,
        updatedAt: new Date(agent.updatedAt),
    }
}
export function mapAgentUpdate(agent: any): Models.PracticeAgent {
    const activatedAt = agent.activated_at ? new Date(agent.activated_at) : undefined
    return {
        id: agent.id,
        activationCode: agent.activation_code,
        active: agent.active,
        activatedAt: activatedAt,
        agentIdentifier: agent.agent_identifier,
        createdAt: new Date(agent.created),
        email: agent.email,
        externalCustomerId: agent.external_customer_id,
        futureDays: agent.future_days,
        integrationTypeString: agent.integration_type_string,
        name: agent.name,
        phone: agent.phone,
        practiceId: agent.practice_id,
        locationId: agent.location_id,
        sendData: agent.send_data,
        sharedKey: agent.shared_key,
        updatedAt: new Date(agent.updated_at),
    }
}

export function mapIntegrationType(type: Api.IntegrationType): Models.IntegrationType {
    return {
        displayName: type.display_name,
        externallyManagedLocations: type.externally_managed_locations,
        id: type.id,
        name: type.name,
    }
}

export function mapPractice(practice: Api.Practice): Models.Practice {
    return {
        id: practice.id,
        name: practice.name,
        active: practice.active,
        created: new Date(practice.created),
        products: practice.products && practice.products.map(mapProduct),
        locations: practice.locations && practice.locations.map(mapLocationForPractice),
        logo: practice.logo,
        agents: practice.agents && practice.agents.map(mapAgent),
        integrationType: practice.integration_type && mapIntegrationType(practice.integration_type),
        hasEmailNotificationSubscribers: null,
        externalHeartbeatPracticeId: practice.external_heartbeat_practice_id,
        isVyneCreated: practice.is_vyne_created || false,
        salesforceType: practice.salesforce_type || 'simplifeye',
    }
}

export function mapLocationScheduling(
    locationScheduling: Api.SchedulingPracticeLocationJarvisInfo | Api.LocationScheduling,
): Models.LocationScheduling {
    return {
        id: locationScheduling.id,
        software: locationScheduling.software,
        locationId: locationScheduling.location_id,
        version: locationScheduling.version,
        timezone: locationScheduling.timezone,
        ipAddress: locationScheduling.ip_address,
        accessKey: locationScheduling.access_key,
        secretKey: locationScheduling.secret_key,
        connectorDownloadUrl: locationScheduling.connector_download_url,
        connectorManualUrl: locationScheduling.connector_manual_url
            ? locationScheduling.connector_manual_url[locationScheduling.software]
            : undefined,
        status: locationScheduling.status,
        syncPercent: locationScheduling.sync_percent,
    }
}

function mapLocationPaymentsInfo(
    locationPaymentsInfo: Api.LocationPaymentsInfo,
): Array<Models.LocationPaymentsTerminalInfo> {
    return locationPaymentsInfo.terminal.terminalDevices.map(t => ({
        id: t.terminalId,
        nickname: t.terminalNickname,
        backgroundColor: t.backgroundColor || '',
        readerSerial: t.stripeCardReaderSerialNumber,
        // pairingCode: t.androidMacAddress,
    }))
}

function getLocationPaymentsStatus(curLocations: Models.PracticeLocation[], location: Models.PracticeLocation) {
    const i = curLocations.findIndex(loc => loc.id === location.id)
    if (i !== -1) {
        location.isTrainingComplete = curLocations[i].isTrainingComplete
        location.stripeID = curLocations[i].stripeID
        location.paymentsRates = curLocations[i].paymentsRates
        location.allFieldsCompleted = curLocations[i].allFieldsCompleted
    }
    return location
}

export function reducer(state: PracticesState = initialState(), action: PracticesAction): PracticesState {
    switch (action.type) {
        case 'DELETE_PRACTICE_LOCATION': {
            return iassign(
                state,
                next => next.practices[action.location.practice_id].locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }
                    return nextLocations.filter(location => location.id !== action.location.id)
                },
            )
        }
        case 'RECEIVE_PRACTICES': {
            return iassign(state, next => {
                const mappedPractices: Models.Practice[] = action.practices.map(mapPractice)

                next.practicesPaginationInfo = action.paginationInfo
                next.practices = _.keyBy(mappedPractices, 'id')

                return next
            })
        }
        case 'SET_SELECTED_PRACTICE': {
            return iassign(state, next => {
                next.selectedPractice = action.selectedPractice
                return next
            })
        }
        case 'RECEIVE_PERMISSION_LIST': {
            return iassign(state, next => {
                next.permissionList = action.permissions.map(mapPermission)
                return next
            })
        }
        case 'RECEIVE_PRACTICE': {
            return iassign(
                state,
                next => next.practices,
                next => {
                    const practice = {
                        ...action.practice,
                        created: new Date(action.practice.created),
                        products: state.practices[action.practice.id]
                            ? state.practices[action.practice.id].products || []
                            : action.practice.products?.map(mapProduct) || [],
                        locations: action.practice.locations.map(mapLocationForPractice),
                        agents: action.practice.agents ? action.practice.agents.map(mapAgent) : undefined,
                        integrationType: mapIntegrationType(action.practice.integration_type),
                        staff: action.practice.staff && action.practice.staff.map(mapAccount),
                        hasEmailNotificationSubscribers: null,
                        externalHeartbeatPracticeId: action.practice.external_heartbeat_practice_id,
                        isVyneCreated: action.practice.is_vyne_created || false,
                        salesforceType: action.practice.salesforce_type || 'simplifeye',
                    }

                    if (state.practices[action.practice.id]) {
                        practice.locations.forEach(location =>
                            getLocationPaymentsStatus(state.practices[action.practice.id].locations, location),
                        )
                    }

                    next[action.practice.id] = practice
                    return next
                },
            )
        }
        case 'RECEIVE_PRACTICE_LOCATIONS': {
            return iassign(
                state,
                next => next.practices[action.practiceId],
                nextPractice => {
                    if (nextPractice?.locations) {
                        nextPractice.locations = action.locations.map(mapLocation)
                        nextPractice.locations.forEach(location =>
                            getLocationPaymentsStatus(state.practices[action.practiceId].locations, location),
                        )
                    }
                    return nextPractice
                },
            )
        }
        case 'RECEIVE_LOCATION_VYNE_PRODUCTS': {
            return iassign(
                state,
                next => next.practices[action.practiceId].locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }
                    const i = nextLocations.findIndex(el => el.id === action.locationId)
                    if (i === -1) {
                        return nextLocations
                    }

                    nextLocations[i].vyneProducts = action.products
                    return nextLocations
                },
            )
        }
        case 'SET_LOCATION_SURVEY_STATUS_TO_COMPLETED': {
            const locationIndex = state.practices[action.practiceId]?.locations?.findIndex(
                loc => loc.id === action.locationId,
            )

            return iassign(
                state,
                next => next.practices[action.practiceId]?.locations[locationIndex]?.survey,
                nextLocationSurvey => {
                    if (nextLocationSurvey) {
                        nextLocationSurvey.status = 'completed'
                    }
                    return nextLocationSurvey
                },
            )
        }
        case 'RECEIVE_PRACTICE_WITH_LOCATIONS': {
            return iassign(
                state,
                next => next.practices,
                next => {
                    const practice = {
                        ...action.practice,
                        created: new Date(action.practice.created),
                        products: state.practices[action.practice.id]
                            ? state.practices[action.practice.id].products || []
                            : action.practice.products?.map(mapProduct) || [],
                        locations: action.locations.map(mapLocation),
                        agents: action.practice.agents ? action.practice.agents.map(mapAgent) : undefined,
                        integrationType: mapIntegrationType(action.practice.integration_type),
                        staff: action.practice.staff && action.practice.staff.map(mapAccount),
                        hasEmailNotificationSubscribers: null,
                        externalHeartbeatPracticeId: action.practice.external_heartbeat_practice_id,
                        isVyneCreated: action.practice.is_vyne_created || false,
                        salesforceType: action.practice.salesforce_type || 'simplifeye',
                    }

                    practice.locations.forEach(location =>
                        state.practices[action.practice.id]?.locations
                            ? getLocationPaymentsStatus(state.practices[action.practice.id].locations, location)
                            : location,
                    )

                    next[action.practice.id] = practice
                    return next
                },
            )
        }
        case 'RECEIVE_PRACTICE_LOCATION': {
            return iassign(
                state,
                next => next.practices[action.location.practice_id].locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }
                    const i = nextLocations.findIndex(el => el.id === action.location.id)
                    if (i === -1) {
                        nextLocations.push(mapLocation(action.location))
                        return nextLocations
                    }
                    nextLocations[i] = mapLocation(action.location)

                    nextLocations[i] = getLocationPaymentsStatus(
                        state.practices[action.practice.id].locations,
                        nextLocations[i],
                    )

                    return nextLocations
                },
            )
        }
        case 'RECEIVE_CREATED_PRACTICE_LOCATION': {
            return iassign(
                state,
                next => next.practices[action.location.practice_id].locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }

                    nextLocations.push(mapCreatedLocation(action.location))
                    return nextLocations
                },
            )
        }
        case 'RECEIVE_PRACTICE_LOCATION_PROCEDURES': {
            return iassign(
                state,
                next => next.practices[action.practice.id].locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }
                    const locationIndex = nextLocations.findIndex(el => el.id === action.locationId)
                    nextLocations[locationIndex].procedures = _.keyBy(action.procedures, 'id')
                    return nextLocations
                },
            )
        }
        case 'RECEIVE_PRACTICE_LOCATION_PROCEDURE_PROVIDERS': {
            return iassign(
                state,
                next => next.practices[action.practice.id].locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }
                    const locationIndex = nextLocations.findIndex(el => el.id === action.locationId)
                    const procedure = nextLocations[locationIndex]?.procedures?.[action.procedure.id]
                    if (procedure) {
                        procedure.providers = (action.providers ?? []).sort((a, b) =>
                            `${a.last_name}${a.first_name}`.localeCompare(`${b.last_name}${b.first_name}`),
                        )
                    }
                    return nextLocations
                },
            )
        }
        case 'RECEIVE_PROCEDURE_AVAILABILITY': {
            return iassign(
                state,
                next => next.practices[action.location.practiceId].locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }
                    const locationIndex = nextLocations.findIndex(el => el.id === action.location.id)
                    const nextLocationProcedures = nextLocations[locationIndex].procedures
                    if (!nextLocationProcedures) {
                        return nextLocations
                    }

                    if (!nextLocationProcedures[action.procedure.id].availability[action.location.id]) {
                        nextLocationProcedures[action.procedure.id].availability[action.location.id] = {}
                    }

                    nextLocationProcedures[action.procedure.id].availability[action.location.id][action.date] =
                        action.availability

                    return nextLocations
                },
            )
        }
        case 'RECEIVE_AVAILABILITY': {
            return iassign(
                state,
                next => next.practices[action.location.practiceId].locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }
                    const locationIndex = nextLocations.findIndex(el => el.id === action.location.id)
                    const nextLocationProcedures = nextLocations[locationIndex].procedures
                    if (!nextLocationProcedures) {
                        return nextLocations
                    }

                    if (!nextLocationProcedures[action.procedure.id].availability[action.location.id]) {
                        nextLocationProcedures[action.procedure.id].availability[action.location.id] = {}
                    }

                    const startDate = moment(action.startDate, 'YYYY-MM-DD[T]')
                    const endDate = moment(action.endDate, 'YYYY-MM-DD[T]')
                    let range = {}
                    while (startDate.isSameOrBefore(endDate)) {
                        range = {
                            ...range,
                            [startDate.format('YYYY-MM-DD')]: [],
                        }
                        startDate.add(1, 'day')
                    }

                    const availability = action.availability.reduce((result, timeRecord) => {
                        const day = timeRecord.split('T')[0]
                        if (!result[day]) {
                            result[day] = [timeRecord]
                        } else {
                            result[day].push(timeRecord)
                        }
                        return result
                    }, {})

                    nextLocationProcedures[action.procedure.id].availability[action.location.id] = {
                        ...nextLocationProcedures[action.procedure.id].availability[action.location.id],
                        ...range,
                        ...availability,
                    }

                    return nextLocations
                },
            )
        }
        case 'RECEIVE_PRACTICE_LOGO': {
            return iassign(state, next => {
                next.practices[action.practice.id].logo = {
                    ...(next.practices[action.practice.id].logo || {}),
                    ...(action.logo || {}),
                }
                return next
            })
        }
        case 'RECEIVE_PRACTICE_LOGO_BG_COLOR': {
            return iassign(state, next => {
                next.practices[action.practiceId].logo = {
                    ...(next.practices[action.practiceId].logo || {}),
                    bgColor: action.bgColor,
                }
                return next
            })
        }
        case 'RECEIVE_PRACTICE_PRODUCTS': {
            return iassign(
                state,
                next => next.practices[action.practiceId],
                nextPractice => {
                    if (!nextPractice) {
                        return
                    }
                    nextPractice.products = action.products.map(mapProduct)
                    return nextPractice
                },
            )
        }

        case 'RECEIVE_PRACTICE_LOCATION_PRODUCTS': {
            return iassign(
                state,
                next => next.practices[action.practiceId].locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }
                    const locationIndex = nextLocations.findIndex(el => el.id === action.locationId)
                    nextLocations[locationIndex].products = action.products.map(mapProductForLocation)
                    return nextLocations
                },
            )
        }

        case 'RECEIVE_ACCOUNT': {
            return iassign(
                state,
                next => next.practices[action.practice.id].staff,
                nextStaff => {
                    if (!nextStaff || !action.practice.staff) {
                        return []
                    }
                    const i = action.practice.staff.findIndex((el: Models.Account) => el.id === action.account.id)

                    if (i === -1) {
                        nextStaff.push(mapAccount(action.account))
                        return nextStaff
                    }
                    nextStaff[i] = mapAccount(action.account)
                    return nextStaff
                },
            )
        }
        case 'RECEIVE_ACCOUNT_STATUS': {
            return iassign(
                state,
                next => next.practices[action.practice.id].staff,
                nextStaff => {
                    for (const account of nextStaff || []) {
                        if (account.id === action.account.id) {
                            account.active = action.active
                            break
                        }
                    }
                    return nextStaff
                },
            )
        }
        case 'SET_PRACTICE_CREATE_STEP': {
            return iassign(
                state,
                next => next.createState,
                next => {
                    next.step = action.step
                    next.name = action.name
                    return next
                },
            )
        }

        case 'RECEIVE_PRACTICE_AGENTS': {
            return iassign(
                state,
                next => next.practices[action.practice.id],
                next => {
                    if (!action.agents) {
                        return next
                    }
                    next.agents = action.agents.map(mapAgent)
                    return next
                },
            )
        }
        case 'RECEIVE_PRACTICE_AGENT': {
            return iassign(
                state,
                next => next.practices[action.practice.id].agents,
                next => {
                    if (!next) {
                        return
                    }
                    const i = next.findIndex(a => a.id === action.agent.id)
                    if (i === -1) {
                        next.push(mapAgentUpdate(action.agent))
                        return next
                    }
                    next[i] = mapAgentUpdate(action.agent)
                    return next
                },
            )
        }
        case 'RECEIVE_UNBOUND_PRACTICE_AGENT': {
            return iassign(
                state,
                next => next.practices[action.practice.id].agents,
                next => {
                    if (!next) {
                        return
                    }
                    _.remove(next, a => a.id === action.agent.id)
                    return next
                },
            )
        }
        case 'UPDATE_PRACTICE_SEARCH_TERMS': {
            return iassign(state, next => {
                if (action.searchTerms) {
                    const page = action.searchTerms.page || next.searchTerms.page
                    const searchKey =
                        action.searchTerms.searchKey !== undefined
                            ? action.searchTerms.searchKey
                            : next.searchTerms.searchKey

                    next.searchTerms = { page, searchKey }
                }

                return next
            })
        }
        case 'RECEIVE_AVAILABLE_PRACTICE_USER_ROLES': {
            return iassign(state, next => {
                next.userRoles = action.roles

                return next
            })
        }
        case 'RECEIVE_PRACTICE_HAS_EMAIL_NOTIFICATION_SUBSCRIBERS': {
            return iassign(
                state,
                next => next.practices[action.practice.id],
                nextPractice => {
                    if (nextPractice) {
                        nextPractice.hasEmailNotificationSubscribers = action.hasEmailNotificationSubscribers
                    }
                    return nextPractice
                },
            )
        }
        case 'RECEIVE_LOCATION_SCHEDULING_DATA': {
            return iassign(
                state,
                next => next.practices[action.practice.id].locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }

                    const i = nextLocations.findIndex(el => el.id === action.location.id)
                    if (i === -1) {
                        return nextLocations
                    }

                    nextLocations[i].schedulingDetails = action.schedulingData
                        ? mapLocationScheduling(action.schedulingData)
                        : undefined

                    return nextLocations
                },
            )
        }
        case 'RECEIVE_LOCATION_CONNECT_PAYMENT_INFO_DATA': {
            return iassign(
                state,
                next => next.practices[action.practiceId].locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }

                    const i = nextLocations.findIndex(el => el.id === action.locationId)
                    if (i === -1) {
                        return nextLocations
                    }

                    nextLocations[i].connectPaymentInfo = Boolean(action.connectPaymentInfoData)
                        ? action.connectPaymentInfoData
                        : null

                    return nextLocations
                },
            )
        }
        case 'RECEIVE_LOCATIONS_SCHEDULING_DATA': {
            return iassign(
                state,
                next => next.practices[action.practiceId]?.locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }

                    action.schedulingData.forEach(
                        (schedulingLocationInfo: ModelsV2.Practice.PracticeSchedulingLocationsData) => {
                            const i = nextLocations.findIndex(
                                el => el.id === schedulingLocationInfo.practice_location_id,
                            )

                            if (i !== -1) {
                                nextLocations[i].hasDirectScheduling = schedulingLocationInfo.has_direct_scheduling
                                nextLocations[i].agentLocationActive = schedulingLocationInfo.active
                            }
                        },
                    )

                    return nextLocations
                },
            )
        }
        case 'RECEIVE_LOCATION_PAYMENTS_INFO': {
            return iassign(
                state,
                next => next.practices[action.practiceId]?.locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }

                    const i = nextLocations.findIndex(l => l.id === action.locationId)

                    if (i > -1) {
                        nextLocations[i].paymentsInfo = mapLocationPaymentsInfo(action.paymentsInfo)
                    }

                    return nextLocations
                },
            )
        }
        case 'RECEIVE_LOCATION_STRIPE_ACCOUNT_ID': {
            return iassign(
                state,
                next => next.practices[action.practiceId]?.locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }

                    const i = nextLocations.findIndex(l => l.id === action.locationId)

                    if (i > -1) {
                        nextLocations[i].stripeConnectedAccountId = action.stripeAccountId
                        nextLocations[i].stripeLocationId = action.stripeLocationId
                        nextLocations[i].stripeID = true
                        if (nextLocations[i].paymentsRates && nextLocations[i].stripeID) {
                            nextLocations[i].allFieldsCompleted = true
                        } else {
                            nextLocations[i].allFieldsCompleted = false
                        }
                    }

                    return nextLocations
                },
            )
        }
        case 'RECEIVE_LOCATION_STATEMENT_DESCRIPTOR': {
            return iassign(
                state,
                next => next.practices[action.practiceId]?.locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }

                    const i = nextLocations.findIndex(l => l.id === action.locationId)

                    if (i > -1) {
                        nextLocations[i].statementDescriptor = action.statementDescriptor
                    }

                    return nextLocations
                },
            )
        }
        case 'UPDATE_ALL_LOCATIONS_STRIPE_ACCOUNT_ID': {
            return iassign(
                state,
                next => next.practices[action.practiceId]?.locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }

                    nextLocations = nextLocations.map(l => {
                        l.stripeConnectedAccountId = action.stripeAccountId
                        return l
                    })

                    return nextLocations
                },
            )
        }
        case 'UPDATE_SINGLE_LOCATION_STRIPE_ACCOUNT_ID': {
            return iassign(
                state,
                next => next.practices[action.practiceId]?.locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }

                    const i = nextLocations.findIndex(l => l.id === action.locationId)

                    if (i > -1) {
                        nextLocations[i].stripeConnectedAccountId = action.stripeAccountId
                    }

                    return nextLocations
                },
            )
        }
        case 'REMOVE_LOCATION_PAYMENTS_TERMINAL': {
            return iassign(
                state,
                next => next.practices[action.practiceId]?.locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }

                    const i = nextLocations.findIndex(l => l.id === action.locationId)

                    if (i > -1) {
                        nextLocations[i].paymentsInfo = nextLocations[i].paymentsInfo?.filter(
                            locationTerminal => locationTerminal.id !== action.terminalId,
                        )
                    }

                    return nextLocations
                },
            )
        }
        case 'ADD_LOCATION_PAYMENTS_TERMINAL': {
            return iassign(
                state,
                next => next.practices[action.practiceId]?.locations,
                nextLocations => {
                    if (!nextLocations) {
                        return
                    }

                    const i = nextLocations.findIndex(l => l.id === action.locationId)

                    if (i > -1) {
                        const currentList: Models.LocationPaymentsTerminalInfo[] = nextLocations[i].paymentsInfo || []
                        currentList.push({
                            id: action.terminalData.id,
                            nickname: action.terminalData.nickname,
                            backgroundColor: action.terminalData.backgroundColor,
                            readerSerial: action.terminalData.readerSerial,
                            pairingCode: action.terminalData.pairingCode,
                        })
                        nextLocations[i].paymentsInfo = currentList
                    }

                    return nextLocations
                },
            )
        }
        case 'RECEIVE_USER_PHONE_NUMBER_BLACK_LIST': {
            return iassign(
                state,
                next => next.practices[action.practiceId].staff,
                staff => {
                    const user = staff?.find(user => user.id === action.accountId)
                    if (user) {
                        user.phoneNumberBlackList = action.phoneNumberBlackList
                    }
                    return staff
                },
            )
        }
        case 'SAVING_PRACTICE_PRODUCTS': {
            return iassign(
                state,
                next => next.isSavingPracticeProducts,
                () => action.isSaving,
            )
        }

        case 'SAVING_PRACTICE_LOCATION_PRODUCTS': {
            return iassign(
                state,
                next => next.isSavingPracticeLocationProducts,
                () => action.isSaving,
            )
        }

        case 'SET_IS_SAVING_PRACTICE_STAFF': {
            return iassign(
                state,
                next => next.isSavingPracticeStaff[action.practiceId],
                () => action.isSavingPracticeStaff,
            )
        }

        case 'UPDATE_LOCATION_COMPLETION_STATUS': {
            return iassign(
                state,
                next => next.practices[action.practiceId].locations,
                nextLocations => {
                    if (!nextLocations) return
                    let index = nextLocations.findIndex(location => location.id === action.locationId)
                    if (index > -1) {
                        nextLocations[index].allFieldsCompleted = action.allFieldsCompleted
                        //  nextLocations[index].completionStatus = action.completionStatus
                    }
                    return nextLocations
                },
            )
        }

        case 'UPDATE_PAYMENT_RATES_STATUS': {
            return iassign(
                state,
                next => next.practices[action.practiceId].locations,
                nextLocations => {
                    if (!nextLocations) return
                    let index = nextLocations.findIndex(location => location.id === action.locationId)
                    if (index > -1) {
                        nextLocations[index].paymentsRates = action.status
                        if (nextLocations[index].paymentsRates && nextLocations[index].stripeID) {
                            nextLocations[index].allFieldsCompleted = true
                        } else {
                            nextLocations[index].allFieldsCompleted = false
                        }
                    }
                    return nextLocations
                },
            )
        }

        case 'RECEIVE_LOCATION_MILESTONE': {
            return iassign(
                state,
                next => next.practices[action.practiceId]?.locations,
                location => {
                    if (!location) {
                        return
                    }
                    const i = location.findIndex(location => location.id === action.locationId)
                    if (i > -1) {
                        location[i].isTrainingComplete = action.isCompleted
                    }
                    return location
                },
            )
        }

        case 'RECEIVE_PRACTICE_LOCATIONS_AGENT_MODULES': {
            return iassign(
                state,
                next => next.practiceLocationsAgentModules,
                nextPracticeLocationsAgentModules => {
                    nextPracticeLocationsAgentModules[action.practiceId] = action.schedulingData
                    return nextPracticeLocationsAgentModules
                },
            )
        }
        case 'FETCHING_ALL_PRACTICE_LOCATIONS': {
            return iassign(
                state,
                next => next.isFetchingAllPracticeLocations[action.practiceId],
                () => action.fetching,
            )
        }
        default:
            return state
    }
}
