import { mapAmpliftyWebcodeSurveys, mapAmplifyWebcodeConnectSurveys } from '../../ApiV2/mappers/survey/webcodes'
import { mapCloseChatMetadataToChatMetadata } from '../../ApiV2/mappers/v2/chat'
import ApiService from '../../ApiV2/service'

export default class AmplifyService extends ApiService {
    async postAmplifyWebsite(practiceId: string, url: string, practiceLocationIds: string[]) {
        const { data } = await this.fetch(
            `practices/${practiceId}/amplify-web-codes?include[]=survey&include[]=amplify_web_code_practice_location`,
            {
                method: 'POST',
                body: JSON.stringify({
                    url: url,
                    practice_location_ids: practiceLocationIds,
                }),
            },
            undefined,
            undefined,
            true,
        )

        return data
    }

    async getAmplifyWebcodes(practiceId: string) {
        const { data } = (await this.fetch(
            `practices/${practiceId}/amplify-web-codes?include[]=survey&include[]=amplify_web_code_practice_location&include[]=practice_specialty_type&sort=url`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as Api.StructuredResponse<Array<ApiV2.Amplify.WebCode>>
        return data
    }

    async getPaginatedAmplifyWebcodes(practiceId: string, params?: { search: string; page: number }) {
        const currentPage = params?.page || 1
        const searchQuery = params?.search || ''
        const response = (await this.fetch(
            `practices/${practiceId}/amplify-web-codes?include[]=survey&include[]=amplify_web_code_practice_location&include[]=practice_specialty_type&sort=url&search=${searchQuery}&page=${currentPage}&limit=8`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<Array<ApiV2.Amplify.WebCode>>

        return response
    }

    async getAmplifyWebcode(webCodeId: string) {
        const { data } = (await this.fetch(
            `amplify-web-codes/${webCodeId}/?include[]=survey&include[]=amplify_web_code_practice_location&include[]=practice_specialty_type`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )) as Api.StructuredResponse<ApiV2.Amplify.WebCode>
        return data
    }

    async putWebCode(webCode: Models.WebCode, updates: Partial<ApiV2.Amplify.UpdateWebCode>) {
        const { data } = (await this.fetch(
            `amplify-web-codes/${webCode.id}?include[]=survey&include[]=amplify_web_code_practice_location`,
            {
                method: 'PUT',
                body: JSON.stringify(updates),
            },
            undefined,
            undefined,
            true,
        )) as Api.StructuredResponse<ApiV2.Amplify.WebCode>
        return data
    }

    async deleteWebCode(webCodeId: string) {
        await this.fetch(`amplify-web-codes/${webCodeId}`, {
            method: 'DELETE',
        })
    }

    async putSurvey(webCodeId: string, surveyId: string, section: any) {
        const sectionData = {
            answers: section?.questions.map((q: any) => {
                return {
                    question_id: q.id,
                    answer_schema: {
                        value: q.answer_schema.value || '',
                    },
                }
            }),
        }

        const { data } = await this.fetch(
            `amplify-web-codes/${webCodeId}/surveys/${surveyId}/sections/${section.id}/update-answers`,
            {
                method: 'PUT',
                body: JSON.stringify(sectionData),
            },
            undefined,
            undefined,
            true,
        )
        return data
    }

    async getAmplifyWebcodeSurvey(amplifyWebCodeId: string, surveyId: string) {
        const { data: surveyData } = await this.fetch(
            `amplify-web-codes/${amplifyWebCodeId}/surveys/${surveyId}`,
            {
                method: 'GET',
            },
            undefined,
            undefined,
            true,
        )

        return mapAmpliftyWebcodeSurveys(surveyData.survey_layout.sections, surveyData.survey_layout.name)
    }

    async getAmplifyWebcodeConnectSurvey(amplifyWebcodeId: string, connectSurveyId: string) {
        const { data: connectSurveyData } = await this.fetch(
            `amplify-web-codes/${amplifyWebcodeId}/connect-surveys/${connectSurveyId}?include[]=connect_available_hours`,
            {
                method: 'GET',
            },
        )

        return mapAmplifyWebcodeConnectSurveys(connectSurveyData)
    }

    async postCloseAmplifyChatMetadata(chatId: string, close: ApiV2.Amplify.ChatMetadataClose) {
        try {
            const { data: updated } = (await this.fetch(
                `amplify-chat-metadata/${chatId}/close`,
                {
                    method: 'POST',
                    body: JSON.stringify(close),
                },
                undefined,
                undefined,
                true,
            )) as Api.StructuredResponse<ApiV2.Amplify.CloseChatMetadata>

            return mapCloseChatMetadataToChatMetadata(updated)
        } catch (err) {
            throw err
        }
    }

    async getAmplifyChatMetadataSchedulingAppointments(chatId: string) {
        try {
            const { data: appointments } = (await this.fetch(
                `amplify-chat-metadata/${chatId}/scheduling-appointments`,
                {
                    method: 'GET',
                },
            )) as Api.StructuredResponse<ApiV2.Amplify.SchedulingAppointment[]>

            return appointments
        } catch (err) {
            throw err
        }
    }

    async getAmplifyReferrals(practiceId: string, searchParams: ApiV2.Amplify.ReferralSearchTerms) {
        const {
            start_date,
            end_date,
            search = '',
            page = 1,
            limit = 25,
            order = 'desc',
            sort = 'updated',
            location_ids = [],
        } = searchParams

        const queryParams: any = {
            search,
            limit,
            page,
            sort,
            order,
        }
        if (location_ids.length > 0) {
            queryParams.location_ids = location_ids
        }
        if (start_date) {
            queryParams.start_date = start_date
        }
        if (end_date) {
            queryParams.end_date = end_date
        }

        const { data: referrals, metadata } = (await this.fetch(
            `practices/${practiceId}/amplify-referrals`,
            {
                method: 'GET',
            },
            queryParams,
            undefined,
            true,
        )) as ApiV2.StructuredResponse<Array<ApiV2.Amplify.ReferralV2>>

        return { paginationInfo: metadata?.pagination_info, data: referrals }
    }
}
