import { useEffect, useMemo, useState } from 'react'

import { moveDefaultLocationToTop } from '../../../../modules/shared/sortUtils'
import { searchAmplifyWebCodesLocations } from '../../../amplify/actions'
import CustomMultiselectField from '../CustomMultiselectField'

const PAGE_LIMIT = 100
const HIDE_SEARCH_IF_LESS_THAN_VALUE = 4

export type WebcodeLocationMultiselectFieldProps = {
    practice: Models.Practice
    webCodeLocations: Models.AmplifyWebCodeLocationsData
    webCode: Models.WebCode
    selectedItems?: string[]
    keyProperty?: string
    displayProperty?: string
    isOptionDisabled?: (id: string) => boolean
    locationSchedulingStatusReason?: (id: string) => string
    placeholder?: string
    label?: string
    selectAllLabel?: string
    helperText?: string
    error?: boolean | string
    errorMessage?: string
    disabled?: boolean
    search?: boolean
    maxSelected?: number
    onlyEnabledWebCodeLocations?: boolean
    loading?: boolean
    onSelectElement: (values: string[]) => void
    onSearch?: (searchTerm?: string) => void
    searchAmplifyWebCodesLocations: typeof searchAmplifyWebCodesLocations
}

export type Props = WebcodeLocationMultiselectFieldProps

const WebcodeLocationMultiselectField = ({
    practice,
    webCode,
    webCodeLocations,
    selectedItems = [],
    keyProperty = 'practiceLocationId',
    displayProperty = 'name',
    placeholder = 'Choose a Location',
    label = 'Practice Location',
    errorMessage = 'Please select a location',
    search = true,
    maxSelected = 1,
    helperText,
    error,
    disabled,
    loading = false,
    onlyEnabledWebCodeLocations,
    onSelectElement,
    onSearch,
    searchAmplifyWebCodesLocations,
    isOptionDisabled,
    locationSchedulingStatusReason,
}: Props) => {
    const [isLoaded, setLoaded] = useState<boolean>(false)
    const [preselectedLocation, setPreselectedLocation] = useState(false)
    const practiceId = practice?.id
    const webCodeId = webCode?.id

    useEffect(() => {
        const areLocationsLoaded =
            (webCodeLocations.currentPage ?? 0) === (webCodeLocations?.paginationInfo?.allPages ?? 1)
        if (!webCodeLocations.isFetching && !areLocationsLoaded) {
            searchAmplifyWebCodesLocations(practiceId, webCodeId, {
                page: (webCodeLocations.currentPage ?? 0) + 1,
                limit: PAGE_LIMIT,
            })
        }

        setLoaded(areLocationsLoaded)
    }, [searchAmplifyWebCodesLocations, practiceId, webCodeId, webCodeLocations])

    const locations = useMemo(() => {
        const webCodeLocationsLocations = webCodeLocations.locations.sort(moveDefaultLocationToTop)
        const patchedLocations = isOptionDisabled
            ? webCodeLocationsLocations.map(location => ({
                  ...location,
                  disabled: isOptionDisabled(location[keyProperty]),
                  nameWithDisabledReason: locationSchedulingStatusReason
                      ? `${location.name}${locationSchedulingStatusReason(location[keyProperty])}`
                      : location.name,
              }))
            : webCodeLocationsLocations
        return Boolean(onlyEnabledWebCodeLocations)
            ? patchedLocations.filter(location => location.enabled)
            : patchedLocations
    }, [
        isOptionDisabled,
        locationSchedulingStatusReason,
        onlyEnabledWebCodeLocations,
        webCodeLocations.locations,
        keyProperty,
    ])

    const filteredLocations = useMemo(() => {
        return locations.filter(l => (typeof l.disabled === 'undefined' ? true : l.disabled === false))
    }, [locations])

    const hasSelectedItem = Boolean(selectedItems[0])

    useEffect(() => {
        if (filteredLocations.length === 1 && !preselectedLocation && !hasSelectedItem) {
            onSelectElement([filteredLocations[0].practiceLocationId])
            setPreselectedLocation(true)
        }
    }, [preselectedLocation, hasSelectedItem, filteredLocations, onSelectElement])

    return (
        <CustomMultiselectField
            items={locations}
            maxSelected={maxSelected}
            selectedItems={selectedItems}
            keyProperty={keyProperty}
            search={search && locations.length > HIDE_SEARCH_IF_LESS_THAN_VALUE}
            displayProperty={locationSchedulingStatusReason ? 'nameWithDisabledReason' : displayProperty}
            disableDropDown={Boolean(selectedItems.length === 1 && locations.length === 1)}
            placeholder={placeholder}
            searchPlaceholder="Search Location"
            label={label}
            error={error}
            errorMessage={errorMessage}
            onSelectElement={onSelectElement}
            onSearch={onSearch}
            disabled={disabled || loading}
            helperText={helperText}
            isLoading={!isLoaded || loading}
            grayedOutPart={'- writeback set to off'}
        ></CustomMultiselectField>
    )
}

export default WebcodeLocationMultiselectField
