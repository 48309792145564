import React from 'react'
import { Icon } from '@mui/material'
import Grid from '@mui/material/Grid'

import { PracticeLocationReschedulingConfiguration } from '../../../models/v2/Practice'
import CustomHoverBoxV2 from '../../shared/custom-hover-box/CustomHoverBoxV2'

import CustomCheckbox from './CustomCheckbox'

const moduleName = 'cancel-reschedule-settings-locations-list'

type Props = {
    location: ApiV2.Practice.PracticeLocationFull
    locationSetting: PracticeLocationReschedulingConfiguration | undefined
    onUpdateSettings: (
        key: string,
        value: string | number | boolean,
        locationSetting: PracticeLocationReschedulingConfiguration | undefined,
        locationId: string,
    ) => void
    useGlobalSettingsChecked: boolean
    hasGlobalSettings: boolean
}

const ApplyGlobalSettingsCheckbox = ({
    useGlobalSettingsChecked,
    hasGlobalSettings,
    onUpdateSettings,
    locationSetting,
    location,
}: Props) => {
    return (
        <Grid
            item={true}
            xs={12}
            sm={3}
            md={2}
            lg={1}
            className={`${moduleName}__table-item details ${moduleName}__checkbox`}
            style={{ width: '15%' }}
        >
            <CustomCheckbox
                className={`${moduleName}__apply-global-settings-checkbox`}
                disabled={!hasGlobalSettings || location.is_online_scheduling_writeback_enabled === false}
                checked={useGlobalSettingsChecked}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                        onUpdateSettings('use_global_settings', true, locationSetting, location.id)
                    } else {
                        onUpdateSettings('use_global_settings', false, locationSetting, location.id)
                    }
                }}
                label={
                    <div
                        style={{
                            display: 'flex',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                opacity: location.is_online_scheduling_writeback_enabled === false ? 0.5 : 1,
                            }}
                        >
                            Apply
                            <br /> Global Settings
                        </div>
                        <div style={{ position: 'absolute', top: '8px', right: '-16px' }}>
                            {location.is_online_scheduling_writeback_enabled === false && (
                                <CustomHoverBoxV2
                                    className="text-tooltip"
                                    title={`This location cannot accept direct Cancellations or Reschedules because PMS
                                            Writeback is not enabled. To enable Writeback, please visit the “Writeback”
                                            tab.`}
                                />
                            )}
                        </div>
                    </div>
                }
            />
        </Grid>
    )
}

export default ApplyGlobalSettingsCheckbox
