import { useState } from 'react'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'

import './SelfSchedulingWebCodeConfirmationBox.sass'

export type SelfSchedulingWebCodeConfirmationBoxType = 'softDeleteSelfSchedulingWebCode'

export type SelfSchedulingWebCodeConfrimationBoxProps = {
    type?: SelfSchedulingWebCodeConfirmationBoxType
    title?: string
}

export type SelfSchedulingWebCodeConfirmationBoxDispatch = {
    confirmFn?: () => void
    closeFn: () => void
}

export interface SelfSchedulingWebCodeConfirmationBoxParams {
    type: SelfSchedulingWebCodeConfirmationBoxType
    callback: () => void
    title?: string
}

type Props = SelfSchedulingWebCodeConfrimationBoxProps & SelfSchedulingWebCodeConfirmationBoxDispatch

const SelfSchedulingWebCodeConfirmationBox = ({ title, type, confirmFn, closeFn }: Props) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)

    const buildText = () => {
        switch (type) {
            case 'softDeleteSelfSchedulingWebCode':
                return (
                    <>
                        <Grid
                            container={true}
                            spacing={2}
                            className="title"
                            wrap="wrap"
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                        >
                            <Grid item={true}>
                                <i className="material-icons red">warning</i>
                            </Grid>
                            <Grid item={true} className="text">
                                <div>Are you sure you want to delete</div>
                                this Scheduling web code?
                            </Grid>
                        </Grid>
                    </>
                )

            default:
                return null
        }
    }

    const buildConfirmBtn = () => {
        const onConfirm = async () => {
            setIsButtonDisabled(true)
            confirmFn && (await confirmFn())
            setIsButtonDisabled(false)
            closeFn()
        }
        switch (type) {
            case 'softDeleteSelfSchedulingWebCode':
                return (
                    <Button color="primary" variant="contained" onClick={onConfirm}>
                        Delete
                    </Button>
                )
            default:
                return null
        }
    }

    return (
        <div className="self-scheduling-confirmation-container">
            <div className="confirmation-inner-container">
                {buildText()}
                <Grid container={true} spacing={2} className="buttons-container" justifyContent="center">
                    <Grid item={true}>
                        <Button variant="outlined" onClick={closeFn}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item={true}>{buildConfirmBtn()}</Grid>
                    {isButtonDisabled && (
                        <div className="circular-progress-loader">
                            <CircularProgress size={48} />
                        </div>
                    )}
                </Grid>
            </div>
        </div>
    )
}

export default SelfSchedulingWebCodeConfirmationBox
