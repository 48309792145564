import React, { useEffect, useMemo, useState } from 'react'
import ReactLoading from 'react-loading'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { Checkbox, FormControlLabel } from '@mui/material'
import Icon from '@mui/material/Icon'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { oneLine } from 'common-tags'
import union from 'lodash/union'

import { CheckboxTheme } from '../../../Theme'
import CustomHoverBox from '../../shared/custom-hover-box/CustomHoverBox'
import CustomHoverBoxV2 from '../../shared/custom-hover-box/CustomHoverBoxV2'
import { softerGray } from '../../shared/styles/colors'
import { extendMuiTheme } from '../styles/utils'

import './CustomMultiselectField.sass'

const moduleName = 'custom-multiselect'
const DISABLED_PROPERTY = 'disabled'

type EventProps = {
    onSelectElement: (values: string[]) => void
    onSearch?: (searchTerm?: string) => void
}
type FieldProps = {
    items: object[]
    selectedItems?: string[]
    keyProperty: string
    displayProperty: string
    placeholder?: string
    searchPlaceholder?: string
    label?: string
    selectAllLabel?: string
    helperText?: string
    error?: boolean | string
    errorMessage?: string
    disabled?: boolean
    withTooltip?: boolean
    search?: boolean
    maxSelected?: number
    isLoading?: boolean
    disableDropDown?: boolean
    isRequired?: boolean
    grayedOutPart?: string
    disabledOptions?: boolean
    withTooltipIcon?: boolean
}
type Props = EventProps & FieldProps

const cache = createCache({
    key: 'css',
    prepend: true,
})

export const MultiselectCheckboxTheme = extendMuiTheme(CheckboxTheme, {
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: '14px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    height: '30px',
                    padding: '0 3px 3px',
                    width: '30px',
                },
            },
        },
    },
})

const CustomMultiselectField = ({
    items = [],
    selectedItems,
    keyProperty,
    displayProperty,
    placeholder,
    label,
    selectAllLabel,
    helperText,
    error,
    errorMessage,
    disabled,
    search,
    withTooltip = false,
    withTooltipIcon = false,
    onSelectElement,
    maxSelected,
    onSearch,
    isLoading,
    searchPlaceholder = 'Search',
    disableDropDown = false,
    isRequired = false,
    grayedOutPart = '',
    disabledOptions = false,
}: Props) => {
    const [active, setActive] = useState<boolean>(false)
    const [isDropdownToggled, setDropdownToggled] = useState<boolean>(false)
    const [localItemsKeys, setLocalItemsKeys] = useState<string[]>(selectedItems ?? [])
    const [searchKeyword, setSearchKeyword] = useState<string>('')

    const isAll = localItemsKeys.length === items.length && localItemsKeys.length > 0
    const isMultiselect = maxSelected !== 1
    const isMaxSelected: boolean =
        maxSelected !== undefined && isMultiselect && (selectedItems?.length ?? 0) >= maxSelected

    const closeDropdownOnClick = () => {
        setActive(false)
        setDropdownToggled(false)
    }

    useEffect(() => {
        window.addEventListener('click', closeDropdownOnClick)

        return () => {
            window.removeEventListener('click', closeDropdownOnClick)
        }
    }, [])

    useEffect(() => {
        if (!selectedItems?.length) {
            setLocalItemsKeys([])
            return
        }
        if (isMultiselect) {
            setLocalItemsKeys(prevSelectedItems =>
                union(prevSelectedItems, selectedItems).filter(item => Boolean(item)),
            )
        } else {
            setLocalItemsKeys(selectedItems.filter(item => Boolean(item)))
        }
    }, [selectedItems, isMultiselect])

    const preventPropagation = (event: any) => {
        event.stopPropagation()
        event.nativeEvent.stopImmediatePropagation()
    }

    const onFocus = () => setActive(true)

    const toggleDropdown = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (disableDropDown) {
            return
        }
        preventPropagation(event)
        setDropdownToggled(!isDropdownToggled)
    }

    const updateSelectedElements = (newItemsKeys: string[]) => {
        setLocalItemsKeys(newItemsKeys)
        onSelectElement(newItemsKeys)
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const itemIndex = localItemsKeys.indexOf(event.target.value)

        if (itemIndex < 0) {
            const newItemsKeys = [...localItemsKeys, event.target.value]
            updateSelectedElements(newItemsKeys)
        } else {
            const newItemsKeys = localItemsKeys.filter((key, index) => index !== itemIndex)
            updateSelectedElements(newItemsKeys)
        }
    }

    const toggleAllItems = () => {
        const newItemsKeys = isAll ? [] : items.filter(item => !item[DISABLED_PROPERTY]).map(item => item[keyProperty])
        updateSelectedElements(newItemsKeys)
    }

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onSearch) {
            onSearch(event.target.value)
        }
        setSearchKeyword(event.target.value)
    }

    const handleSearchClear = () => {
        if (onSearch) {
            onSearch('')
        }
        setSearchKeyword('')
    }

    const filteredItems = useMemo(() => {
        if (!searchKeyword) {
            return items
        }
        return items.filter(item => item[displayProperty].toLowerCase().includes(searchKeyword.toLowerCase()))
    }, [items, searchKeyword, displayProperty])

    const handleSelectSingleOption = (item: object) => () => {
        if (!item[DISABLED_PROPERTY]) {
            updateSelectedElements([item[keyProperty]])
            if (!isMultiselect) {
                closeDropdownOnClick()
            }
        }
    }

    const renderOption = (item: object) => {
        const isChecked = localItemsKeys.indexOf(item[keyProperty]) > -1
        const isDisabled =
            item[DISABLED_PROPERTY] === true || (disabledOptions && item?.['disabledIfCondition'] === true)
        const formControlLabelValue = withTooltip ? (
            <CustomHoverBox className="text-tooltip" title={item[displayProperty]} placement="bottom-start">
                <div>{item[displayProperty]}</div>
            </CustomHoverBox>
        ) : (
            item[displayProperty]
        )

        if (isMultiselect) {
            return (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isChecked}
                            onChange={onChange}
                            value={item[keyProperty]}
                            disabled={(isMaxSelected && !isChecked) || isDisabled}
                        />
                    }
                    label={formControlLabelValue}
                />
            )
        }

        const itemDisplay = item[displayProperty].includes(grayedOutPart) ? (
            <span>
                {item[displayProperty].replace(grayedOutPart, ' ')}
                <span style={{ opacity: 0.5 }}>{grayedOutPart}</span>
            </span>
        ) : (
            item[displayProperty]
        )

        return (
            <label
                className={`${moduleName}__dropdown-option-label ${
                    isChecked ? `${moduleName}__dropdown-option-label--checked` : ''
                } ${isDisabled ? `${moduleName}__dropdown-option-label--disabled` : ''}`}
                onClick={isDisabled ? undefined : handleSelectSingleOption(item)}
            >
                {itemDisplay}
            </label>
        )
    }

    const renderCorrectCustomField = () => {
        const value =
            isAll && selectAllLabel
                ? selectAllLabel
                : localItemsKeys
                      .reduce((values, key) => {
                          if (!key) {
                              return values
                          }

                          const foundItem = items.find(item => item[keyProperty] === key)

                          if (foundItem) {
                              return [...values, foundItem[displayProperty]]
                          }

                          return values
                      }, [])
                      .join(', ')

        const valueDisplay = value.includes(grayedOutPart) ? (
            <span>
                {value.replace(grayedOutPart, ' ')}
                <span style={{ opacity: 0.5 }}>{grayedOutPart}</span>
            </span>
        ) : (
            value
        )

        return (
            <CacheProvider value={cache}>
                <ThemeProvider theme={MultiselectCheckboxTheme}>
                    {isLoading && (
                        <div className={`${moduleName}__loading`}>
                            <ReactLoading type="spin" color={softerGray} height={15} width={15} />
                        </div>
                    )}
                    <button
                        className={`${moduleName}__dropdown-button`}
                        onClick={toggleDropdown}
                        onFocus={onFocus}
                        disabled={disabled}
                    >
                        {value ? (
                            <span className={`${moduleName}__dropdown-text`}>{valueDisplay}</span>
                        ) : (
                            <span className={`${moduleName}__dropdown-placeholder`}>{placeholder}</span>
                        )}
                        {!disableDropDown && (
                            <i className={`${moduleName}__dropdown-arrow material-icons`}>arrow_drop_down</i>
                        )}
                    </button>

                    {isDropdownToggled && (
                        <ul className={`${moduleName}__dropdown`} onClick={preventPropagation}>
                            {search && (
                                <div className={`${moduleName}__search`}>
                                    <Icon>search</Icon>
                                    <input
                                        className={`${moduleName}__search-field`}
                                        placeholder={searchPlaceholder}
                                        value={searchKeyword}
                                        onChange={handleChangeSearch}
                                    />
                                    {searchKeyword && (
                                        <span className={`${moduleName}__search-clear`} onClick={handleSearchClear}>
                                            <Icon>clear</Icon>
                                        </span>
                                    )}
                                </div>
                            )}
                            {items.length > 1 && !searchKeyword && isMultiselect && !maxSelected && (
                                <li className={`${moduleName}__dropdown-item`}>
                                    <StyledEngineProvider injectFirst>
                                        <ThemeProvider theme={MultiselectCheckboxTheme}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={isAll} onChange={toggleAllItems} value="all" />
                                                }
                                                label={selectAllLabel || 'All'}
                                            />
                                        </ThemeProvider>
                                    </StyledEngineProvider>
                                </li>
                            )}
                            {filteredItems.map(item => {
                                return (
                                    <li className={`${moduleName}__dropdown-item`} key={item[keyProperty]}>
                                        {renderOption(item)}
                                    </li>
                                )
                            })}
                        </ul>
                    )}
                </ThemeProvider>
            </CacheProvider>
        )
    }

    return (
        <div className={`${moduleName}__container`}>
            <div
                className={oneLine`
                    ${moduleName}
                    ${error ? `${moduleName}--error` : ''}
                    ${disabled ? `${moduleName}--disabled` : ''}
                    ${active ? `${moduleName}--active` : ''}
                `}
            >
                {label && (
                    <div
                        style={{
                            display: 'flex',
                            position: 'relative',
                        }}
                    >
                        <label
                            className={`${moduleName}__label`}
                            style={{
                                position: 'relative',
                            }}
                        >
                            {label}
                            {isRequired && <span className={`${moduleName}__label-required`}>*</span>}
                            <div style={{ position: 'absolute', top: '-6px', right: '-18px' }}>
                                {withTooltipIcon && (
                                    <CustomHoverBoxV2
                                        className="text-tooltip"
                                        title={`This location cannot accept direct Cancellations or Reschedules because
                                                PMS Writeback is not enabled. To enable Writeback, please visit the
                                                “Writeback” tab.`}
                                    />
                                )}
                            </div>
                        </label>
                    </div>
                )}
                {renderCorrectCustomField()}
                {error ? (
                    <span className={`${moduleName}__helper-text`}>{errorMessage}</span>
                ) : (
                    helperText && <span className={`${moduleName}__helper-text`}>{helperText}</span>
                )}
            </div>
        </div>
    )
}

export default CustomMultiselectField
