export const CANCELLATIONS_VALUES = [
    { value: 'disable', display: 'Do not allow', disabledIfCondition: false },
    { value: 'allow', display: 'Direct Cancellation', disabledIfCondition: true },
    { value: 'request', display: 'Cancellation Request', disabledIfCondition: false },
]

export const RESCHEDULING_VALUES = [
    { value: 'disable', display: 'Do not allow', disabledIfCondition: false },
    { value: 'allow', display: 'Direct Rescheduling', disabledIfCondition: true },
    { value: 'request', display: 'Rescheduling Request', disabledIfCondition: false },
]

export const CONCURRENCY_NUM_ARRAY = Array.from(Array(8).keys()).map(num => num + 1)

export const DAYS_PRIOR_VALUES = CONCURRENCY_NUM_ARRAY.map(num => ({
    value: num.toString(),
    display: `${num} day${num === 1 ? `` : `s`} prior`,
}))
