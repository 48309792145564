import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import CircularProgress from '@mui/material/CircularProgress'
import Icon from '@mui/material/Icon'

import { RootState } from '../../../appReducer'
import AdminAccount from '../../../models/AdminAccount'
import { ManageDirectScheduling } from '../../../models/enums'
import { useAppDispatch } from '../../../util/useAppDispatch'
import { fetchChatAlertTasksList, saveChatAlertTask } from '../../amplify/actions'
import { fetchPracticeWithLocations } from '../../practices/actions'

import ChatCenterAlertTasksList from './ChatCenterAlertTasksList'

import './ChatCenterAlert.sass'
import { F } from 'lodash/fp'

export type Props = {
    account: AdminAccount
    practice: Models.Practice
    selectedChat: Models.ChatCenterSelectedChat
    webCodeId?: string
    webCodeConnect: Models.WebCodeConnect | null
    isConnectAvailable: boolean
}

export interface ScheduleAppointmentModalData {
    open: boolean
}

const moduleName = 'chat-center-alerts'

const ChatCenterAlertManager = ({
    account,
    practice,
    selectedChat,
    webCodeId,
    webCodeConnect,
    isConnectAvailable,
}: Props) => {
    const practiceId = selectedChat.practice.id
    const [topHeight, setTopHeight] = useState<number>(100)
    const handleYPosition = useRef<any>(null)
    const alertRef = useRef<any>(null)
    const alertContentRef = useRef<any>(null)
    const ALERT_BOX_DEFAULT_HEIGHT = 100
    const ALERT_BOX_MAX_HEIGHT = 400
    const ALERT_BOX_EMPTY_MAX_HEIGHT = 170

    const tasks = useSelector((state: RootState) => state.amplify.tasks[selectedChat.id])
    const practiceLocationsByWebCode = useSelector(
        (state: RootState) => (webCodeId && state.v2.practices.practiceLocationsByWebCode?.[webCodeId]) || [],
    )

    const practiceLocations = useSelector(
        (state: RootState) => practiceId && state.v2.practices.practiceLocations[practiceId],
    ) as any[]
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchChatAlertTasksList(selectedChat.practice.id, selectedChat.id))

        if (!selectedChat.initialized && selectedChat.visible) {
            fetchPracticeWithLocations(practiceId)
        }
    }, [selectedChat, practiceId, dispatch])

    useEffect(() => {
        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseup', onMouseUp)

        return () => {
            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('mouseup', onMouseUp)
        }
    })

    const mouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault()
        handleYPosition.current = event.clientY
    }

    const onMouseUp = () => {
        handleYPosition.current = null
    }

    const onMouseMove = (event: MouseEvent) => {
        if (!handleYPosition.current) {
            return
        }

        const newTopHeight = topHeight + event.clientY - handleYPosition.current
        handleYPosition.current = event.clientY

        setTopHeight(newTopHeight)
        if (alertRef && alertRef.current) alertRef.current.style.height = `${topHeight}px`
    }

    const hasConnect = isConnectAvailable && webCodeConnect?.status?.value === 'enabled'

    const webCodeLocationIds = practiceLocationsByWebCode?.map(location => location.practice_location_id)

    const webCodePracticeLocationsHaveDirectScheduling = practice?.locations.some(location => {
        return (
            webCodeLocationIds?.includes(location.id) &&
            location.products?.find(product => product.product.value === 'direct_scheduling' && product.active === true)
        )
    })

    const practiceLocationsByWebCodeWithWritebackDisabled =
        practice?.locations
            ?.filter(
                location =>
                    webCodeLocationIds?.includes(location.id) && location.isOnlineSchedulingWritebackEnabled === false,
            )
            .sort((a, b) => a.name.localeCompare(b.name)) || []

    const practiceLocationsByWebCodeWithWritebackEnabled =
        practice?.locations
            ?.filter(
                location =>
                    webCodeLocationIds?.includes(location.id) && location.isOnlineSchedulingWritebackEnabled === true,
            )
            .sort((a, b) => a.name.localeCompare(b.name)) || []

    const practiceHasDirectSchedulingProduct = practice?.products.some(
        product => product.value === 'direct_scheduling' && product.active === true,
    )

    const hasDirectSchedulingProduct =
        webCodeLocationIds?.length > 0
            ? webCodePracticeLocationsHaveDirectScheduling
            : practiceHasDirectSchedulingProduct

    const hasDirectSchedulingAndAccessToIt = account?.hasAccess(ManageDirectScheduling) && hasDirectSchedulingProduct

    const updateAlertTask = (taskId: string, marked: boolean) => {
        return dispatch(
            saveChatAlertTask(practiceId, {
                chat_alert_id: taskId,
                chat_id: selectedChat.id,
                marked,
            }),
        )
    }

    const calculateMaxHeight = (filteredTasks: Array<any>) => {
        if (!filteredTasks.length) return ALERT_BOX_EMPTY_MAX_HEIGHT

        const currentHeight = alertContentRef.current?.clientHeight + 30
        return currentHeight > ALERT_BOX_MAX_HEIGHT ? ALERT_BOX_MAX_HEIGHT : currentHeight
    }

    if (!tasks) {
        return (
            <div className={`${moduleName}__loader`}>
                <CircularProgress size={50} color="primary" variant="indeterminate" />
            </div>
        )
    }

    if (!hasConnect && !tasks.length && !hasDirectSchedulingAndAccessToIt) {
        return <div />
    }

    const filteredTasks = tasks.filter(task => task.websiteIds.indexOf(String(webCodeId)) >= 0)

    const shouldShowFilteredTasks = tasks.length > 0 && filteredTasks.length > 0
    const shouldShowAlertsTaskSection = shouldShowFilteredTasks || hasDirectSchedulingAndAccessToIt
    const shouldShowAlertsSection = hasConnect || shouldShowAlertsTaskSection
    const alertBoxMaxHeightCalculated = calculateMaxHeight(filteredTasks)

    if (!shouldShowAlertsSection) {
        return <div />
    }

    return (
        <Fragment>
            <div
                className={`${moduleName}`}
                style={{ height: `${ALERT_BOX_DEFAULT_HEIGHT}px`, maxHeight: `${alertBoxMaxHeightCalculated}px` }}
                ref={alertRef as React.RefObject<any>}
            >
                {hasConnect && (
                    <div className={`${moduleName}__connect`}>
                        <span className={`${moduleName}__connect-icon`}>
                            <Icon>laptop</Icon>
                        </span>
                        <span className={`${moduleName}__connect-label`}>CONNECT PRACTICE!</span>
                    </div>
                )}
                {shouldShowAlertsTaskSection && (
                    <Fragment>
                        <div className={`${moduleName}__header`}>Alerts</div>
                        <div className={`${moduleName}__body-container`} ref={alertContentRef}>
                            {hasDirectSchedulingAndAccessToIt && (
                                <ul className={`${moduleName}__fixed-alerts`}>
                                    <li>This practice has an Online Scheduling feature. Click the "Schedule" tab.</li>
                                    {practiceLocationsByWebCodeWithWritebackDisabled?.length > 0 && (
                                        <>
                                            {practiceLocationsByWebCodeWithWritebackDisabled?.length === 1 &&
                                            practiceLocationsByWebCodeWithWritebackEnabled?.length === 0 ? (
                                                <li>
                                                    This practice location has disabled PMS writeback. Appointments may
                                                    be requested but will not be immediately scheduled - and require
                                                    confirmation by the practice.
                                                </li>
                                            ) : (
                                                <li>
                                                    This practice has disabled PMS writeback for{' '}
                                                    {practiceLocationsByWebCodeWithWritebackDisabled
                                                        .map(l => l.name)
                                                        .join(', ')}
                                                    . Appointments may be requested but will not be immediately
                                                    scheduled - and require confirmation by the practice.
                                                </li>
                                            )}
                                        </>
                                    )}
                                </ul>
                            )}
                            {shouldShowFilteredTasks && (
                                <ChatCenterAlertTasksList tasks={filteredTasks} updateAlertTask={updateAlertTask} />
                            )}
                        </div>
                    </Fragment>
                )}
            </div>

            <div className={`${moduleName}__resize-handle`} onMouseDownCapture={mouseDown}>
                <Icon className={`${moduleName}__resize-handle-icon`}>drag_handle</Icon>
            </div>

            <hr className={`${moduleName}__shadow-divider`} />
        </Fragment>
    )
}

export default ChatCenterAlertManager
