import React from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grid from '@mui/material/Grid'
import Icon from '@mui/material/Icon'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'

import { black, white } from '../styles/colors'

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: black,
        borderRadius: '4px',
        color: white,
        fontSize: '14px',
        fontWeight: 400,
        border: `1px solid ${black}`,
        lineHeight: '18px',
        height: 'auto',
        padding: '8px',
        minWidth: '200px',
        maxWidth: '315px',
        '& .MuiTooltip-arrow': {
            color: black,
        },
    },
}))

type Props = {
    title: string
    className?: string
}

const CustomHoverBoxV2 = ({ title, className }: Props) => {
    const [open, setOpen] = React.useState(false)

    const handleTooltipClose = () => {
        setOpen(false)
    }

    const handleTooltipOpen = () => {
        setOpen(true)
    }

    return (
        <Grid item>
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                    <CustomTooltip
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={title}
                        className={className}
                        placement="top"
                        componentsProps={{
                            popper: {
                                disablePortal: true,
                            },
                        }}
                    >
                        <Icon onClick={handleTooltipOpen} style={{ fontSize: 16, cursor: 'pointer' }}>
                            info
                        </Icon>
                    </CustomTooltip>
                </div>
            </ClickAwayListener>
        </Grid>
    )
}

export default CustomHoverBoxV2
