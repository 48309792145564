import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import { ConfigProvider, Switch } from 'antd'
import { ThemeConfig } from 'antd'
import moment from 'moment'

import { setRedirect } from '../../../appActions'
import { RootState } from '../../../appReducer'
import { fetchPracticeLocationV2s, setPracticeLocationsWritebackSettings } from '../../../modules/practices/v2actions'
import { fetchPractice } from '../../practices/actions'
import { fetchConnectedPracticeLocations } from '../cancel-reschedule-settings/v2actions'

import PMSWritebackSettingsPracticeHeader from './PMSWritebackSettingsPracticeHeader'

import './PMSWritebackSettings.sass'

export const theme: ThemeConfig = {
    token: {
        colorPrimary: '#1a4a93',
        colorError: '#B21C1A',
        fontFamily: 'Roboto',
    },
    components: {
        Button: {
            fontSize: 14,
            fontSizeLG: 14,
            fontSizeSM: 12,
            controlHeightLG: 36,
            borderRadiusLG: 4,
            borderRadiusSM: 4,
            borderRadius: 4,
            colorPrimary: '#91C73D',
            colorPrimaryHover: '#6FA12A',
            colorPrimaryActive: '#91C73D',
            colorPrimaryText: '#FFFFFF',
            colorTextDisabled: '#BFBFBF',
            colorLink: '#729DE4',
            controlOutline: 'transparent',
        },
        Tabs: {
            colorText: '#87878A',
            colorPrimary: '#4E4E4F',
            itemHoverColor: '#4E4E4F',
        },
        Checkbox: {
            borderRadiusSM: 2,
            colorPrimary: '#91C73D',
            colorPrimaryHover: '#6FA12A',
            colorPrimaryActive: '#91C73D',
            colorText: '#4E4E4F',
            colorTextDisabled: '#BFBFBF',
        },
        Radio: {
            colorText: '#4E4E4F',
            colorTextDisabled: '#BFBFBF',
            colorPrimary: '#91C73D',
            colorPrimaryHover: '#6FA12A',
            colorPrimaryActive: '#91C73D',
        },
        Switch: {
            colorPrimary: '#91C73D',
            colorPrimaryHover: '#6FA12A',
            colorPrimaryActive: '#91C73D',
        },
        Input: {
            borderRadiusLG: 4,
            borderRadiusSM: 4,
            borderRadius: 4,
            // colorPrimaryHover: '#1a4a93',
            // colorPrimaryActive: '#1a4a93',
            colorErrorBorderHover: '#b21c1a',
            colorErrorActive: '#b21c1a',
            colorText: '#4E4E4F',
            colorTextPlaceholder: '#87878A',
            colorFillAlter: '#F5F5F5',
            colorTextDisabled: '#BFBFBF',
            fontSize: 12,
            controlHeight: 30,
        },
        InputNumber: {
            borderRadiusLG: 4,
            borderRadiusSM: 4,
            borderRadius: 4,
            colorErrorBorderHover: '#b21c1a',
            colorErrorActive: '#b21c1a',
            colorText: '#4E4E4F',
            colorTextPlaceholder: '#87878A',
            colorFillAlter: '#F5F5F5',
            colorTextDisabled: '#BFBFBF',
            fontSize: 12,
            controlHeight: 30,
        },
        DatePicker: {
            borderRadiusLG: 4,
            borderRadiusSM: 4,
            borderRadius: 4,
            colorErrorBorderHover: '#b21c1a',
            colorErrorActive: '#b21c1a',
            colorText: '#4E4E4F',
            colorTextPlaceholder: '#87878A',
            colorFillAlter: '#F5F5F5',
            colorTextDisabled: '#BFBFBF',
            fontSize: 12,
            controlHeight: 30,
        },
        Select: {
            borderRadiusXS: 4,
            borderRadius: 4,
            controlItemBgActive: '#FFFFFF',
            colorText: '#4E4E4F',
            colorTextDisabled: '#BFBFBF',
            colorErrorText: '#B21C1A',
            colorErrorHover: '#B21C1A',
            colorErrorActive: '#B21C1A',
            colorErrorTextActive: '#FFFFFF',
            borderRadiusLG: 2,
            borderRadiusSM: 0,
            paddingXXS: 0,
        },
        Steps: {
            controlItemBgActive: '#FFFFFF',
            colorFillContent: '#FFFFFF',
            colorTextLabel: '#bfbfbf',
        },
        Tag: {
            colorBorder: '#BFBFBF',
            borderRadiusLG: 16,
            borderRadiusSM: 16,
            borderRadiusXS: 16,
            borderRadius: 16,
        },
        Alert: {
            colorSuccessBg: '#F5F5F5',
            colorSuccessBorder: '#F5F5F5',
            colorErrorBg: '#F5F5F5',
            colorErrorBorder: '#F5F5F5',
            colorWarningBg: '#F5F5F5',
            colorWarningBorder: '#F5F5F5',
            colorInfoBg: 'transparent',
            colorInfoBorder: '#1a4a93',
            colorInfo: '#1a4a93',
            borderRadiusLG: 2,
            borderRadiusSM: 2,
            borderRadiusXS: 2,
            borderRadius: 2,
        },
        Table: {
            borderRadiusLG: 2,
            borderRadiusSM: 2,
            borderRadiusXS: 2,
            borderRadius: 2,
            colorText: '#4E4E4F',
            colorTextHeading: '#4E4E4F',
            fontWeightStrong: 500,
            paddingXS: 16,
            controlItemBgActive: '#ffffff',
            controlItemBgActiveHover: '#ffffff',
        },
        Pagination: {
            borderRadiusLG: 2,
            borderRadiusSM: 2,
            borderRadiusXS: 2,
            borderRadius: 2,
            colorPrimary: '#87878A',
            colorPrimaryBorder: '#87878A',
            colorPrimaryHover: '#87878A',
            colorText: '#87878A',
        },
        Collapse: {
            borderRadiusLG: 0,
            borderRadiusSM: 0,
            borderRadius: 0,
            paddingSM: 16,
        },
        Dropdown: {
            borderRadiusLG: 2,
            borderRadiusSM: 0,
            paddingXXS: 0,
            controlItemBgHover: '#E8F3D6',
            controlItemBgActive: '#E8F3D6',
        },
    },
}

const moduleName = 'pms-writeback-settings'

const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
        color: theme.palette.common.black,
        opacity: 0.75,
    },
    tooltip: {
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
        borderRadius: 2,
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
    },
}))

function PMSWritebackTooltip(props: any) {
    const classes = useStylesBootstrap()

    return <Tooltip arrow classes={classes} {...props} />
}

export type Props = RouteComponentProps<{
    practiceId: string
}>

const PMSWritebackSettings = (props: Props) => {
    const practiceId = props.match.params.practiceId

    const connectedPracticeLocations = useSelector(
        (state: RootState) => state.v2.reschedulingConfiguration.connectedPracticeLocations?.[practiceId] || [],
    )

    const [practiceLocationsError, setPracticeLocationsError] = useState('')
    const [loadingLocations, setLoadingLocations] = useState(false)

    const practiceLocations = useSelector(
        (state: RootState) => practiceId && state.v2.practices.practiceLocations[practiceId],
    ) as any[]
    const dispatch = useDispatch()

    const handleToggleActive = (practiceLocationId: string, enabled: boolean) => () => {
        const writebacks = [{ locationId: practiceLocationId, enabled: !enabled }]
        dispatch(setPracticeLocationsWritebackSettings(practiceId, writebacks))
    }

    const locationsWithAgents = practiceLocations?.filter(
        location => Boolean(connectedPracticeLocations?.find(cl => cl.id === location.id)?.id), //moduleType?.id),
    )

    const isAllActive = locationsWithAgents?.every(location => location.is_online_scheduling_writeback_enabled)

    const practiceLocationsSorted = practiceLocations?.sort((a, b) =>
        a.name.toString().localeCompare(b.name.toString()),
    )
    const practice = useSelector((state: RootState) => state.practices.practices[practiceId])
    const practiceFetched = practice?.id

    useEffect(() => {
        if (!practiceFetched) {
            dispatch(fetchPractice(practiceId))
        }
    }, [practiceFetched, practiceId, dispatch])

    const fetchAllPracticeLocations = useCallback(
        async (practiceId, page) => {
            try {
                const response = await dispatch(fetchPracticeLocationV2s(practiceId, page))
                if (response?.pagination_info && response.pagination_info.allPages > page) {
                    fetchAllPracticeLocations(practiceId, page + 1)
                }
            } catch (error) {
                setPracticeLocationsError('Problem with loading practice locations')
            }
        },
        [dispatch],
    )

    const onReturnToPractices = () => {
        dispatch(setRedirect(`/practices`))
    }

    useEffect(() => {
        if (practiceId) {
            setLoadingLocations(true)
            fetchAllPracticeLocations(practiceId, 1)
            setLoadingLocations(false)
        }
    }, [dispatch, fetchAllPracticeLocations, fetchPractice, practiceId])

    useEffect(() => {
        dispatch(fetchConnectedPracticeLocations(practiceId))
    }, [dispatch, practiceId])

    const handleToggleAllActive = () => () => {
        const writebacks = locationsWithAgents?.map(location => {
            return { locationId: location.id, enabled: !isAllActive }
        })
        dispatch(setPracticeLocationsWritebackSettings(practiceId, writebacks))
    }

    if (!practice) {
        return (
            <div className="circular-progress-loader">
                <CircularProgress size={100} color="primary" variant="indeterminate" />
            </div>
        )
    }

    return (
        <div className={moduleName}>
            <div className="detached-button" onClick={onReturnToPractices}>
                <i className="material-icons">arrow_back</i>
            </div>
            <PMSWritebackSettingsPracticeHeader practice={practice} />
            <div className={`${moduleName}__title`}>PMS Writeback Settings</div>
            <div className={`${moduleName}__subtitle-wrapper`}>
                <div className={`${moduleName}__subtitle`}>
                    Control the recording of appointments directly into your Practice Management System.
                </div>
            </div>
            <ConfigProvider theme={theme}>
                <div className={`${moduleName}__content`}>
                    <div className={`${moduleName}__practice-name-header`}>
                        <div className={`${moduleName}__practice-header-left`}></div>
                        <div className={`${moduleName}__practice-header-text`}>Location Name</div>
                    </div>
                    {(!practiceLocationsSorted || loadingLocations) && (
                        <div className={`${moduleName}__circular-progress-loader-body`}>
                            <CircularProgress
                                className={`${moduleName}__circular-progress-spinner`}
                                size={50}
                                color="primary"
                                variant="indeterminate"
                            />
                        </div>
                    )}
                    {practiceLocationsSorted?.length > 1 && (
                        <div className={`${moduleName}__practice-location-row`}>
                            <div className={`${moduleName}__practice-location-switch`}>
                                <Switch checked={isAllActive} size="small" onClick={handleToggleAllActive()} />
                            </div>
                            <div className={`${moduleName}__practice-location-name`}>ALL LOCATIONS</div>
                        </div>
                    )}
                    {practiceLocationsSorted?.map(location => {
                        const noAgent = !Boolean(
                            connectedPracticeLocations?.find(cl => cl.id === location.id)?.id, //moduleType?.id,
                        )
                        return (
                            <div className={`${moduleName}__practice-location-row`}>
                                <div className={`${moduleName}__practice-location-switch`}>
                                    {noAgent ? (
                                        <Switch checked={false} size="small" disabled={true} />
                                    ) : (
                                        <PMSWritebackTooltip
                                            arrow
                                            placement="top"
                                            title={
                                                location.online_scheduling_writeback_updated
                                                    ? `${
                                                          location.is_online_scheduling_writeback_enabled ? `On` : `Off`
                                                      }: [${moment(location.online_scheduling_writeback_updated).format(
                                                          'dddd, MMMM D, YYYY h:mm A',
                                                      )}]`
                                                    : 'No data'
                                            }
                                        >
                                            <Switch
                                                checked={location.is_online_scheduling_writeback_enabled}
                                                size="small"
                                                onClick={handleToggleActive(
                                                    location.id,
                                                    location.is_online_scheduling_writeback_enabled,
                                                )}
                                            />
                                        </PMSWritebackTooltip>
                                    )}
                                </div>
                                {noAgent ? (
                                    <div
                                        className={`${moduleName}__practice-location-name ${moduleName}__practice-location-name--disabled`}
                                    >
                                        {location.name}
                                    </div>
                                ) : (
                                    <div className={`${moduleName}__practice-location-name`}>{location.name}</div>
                                )}
                            </div>
                        )
                    })}
                </div>
            </ConfigProvider>
        </div>
    )
}

export default PMSWritebackSettings
