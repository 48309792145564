import Api from 'Api'
import ApiV2 from 'ApiV2'
import { Dispatch } from 'redux'

import { HeartbeatAgentLocation } from '../../../models/Integrations'

export type ReceiveSelfSchedulingWebCodeList = {
    type: 'RECEIVE_SELF_SCHEDULING_WEB_CODE_LIST'
    practiceId: string
    webCodes: Models.SelfSchedulingWebCode[]
}

export type ReceiveSelfSchedulingWebCodeLocationsList = {
    type: 'RECEIVE_SELF_SCHEDULING_WEB_CODE_LOCATIONS_LIST'
    receiveWebCodeLocationsList: Models.SelfSchedulingReceiveWebCodeLocationsList
}

export type ReceiveSelfSchedulingWebCode = {
    type: 'RECEIVE_SELF_SCHEDULING_WEB_CODE'
    webCode: Models.SelfSchedulingWebCode
}

export type ReceiveSelfSchedulingWebCodeLocation = {
    type: 'RECEIVE_SELF_SCHEDULING_WEB_CODE_LOCATION'
    location: Models.SelfSchedulingWebCodeLocation
}

export type SetWebCodeLocationsRSVPState = {
    type: 'SET_WEB_CODE_LOCATIONS_RSVP_STATE'
    active: boolean
    practiceId: string
    webCodeId: string
    updates: Models.RSVPConnectedLocation[]
}

export type SetAllRSVPsEnabled = {
    type: 'SET_ALL_RSVPS_ENABLED'
    webCodeId: string
    enabled: boolean
}

export function receiveSelfSchedulingWebCodeLocationsList(
    receiveWebCodeLocationsList: Models.SelfSchedulingReceiveWebCodeLocationsList,
): ReceiveSelfSchedulingWebCodeLocationsList {
    return {
        type: 'RECEIVE_SELF_SCHEDULING_WEB_CODE_LOCATIONS_LIST',
        receiveWebCodeLocationsList,
    }
}

export function receiveSelfSchedulingWebCodeList(
    practiceId: string,
    webCodes: Models.SelfSchedulingWebCode[],
): ReceiveSelfSchedulingWebCodeList {
    return {
        type: 'RECEIVE_SELF_SCHEDULING_WEB_CODE_LIST',
        practiceId,
        webCodes,
    }
}

export function receiveSelfSchedulingWebCode(webCode: Models.SelfSchedulingWebCode): ReceiveSelfSchedulingWebCode {
    return {
        type: 'RECEIVE_SELF_SCHEDULING_WEB_CODE',
        webCode,
    }
}

export function receiveSelfSchedulingWebCodeLocation(
    location: Models.SelfSchedulingWebCodeLocation,
): ReceiveSelfSchedulingWebCodeLocation {
    return {
        type: 'RECEIVE_SELF_SCHEDULING_WEB_CODE_LOCATION',
        location,
    }
}

export type ReceiveRSVPEnabledLocations = {
    type: 'RECEIVE_RSVP_ENABLED_LOCATIONS'
    practiceId: string
    enabledLocations: HeartbeatAgentLocation[]
}

export type SetNumberOfEnabledSelfSchedulingWebCodeLocations = {
    type: 'SET_NUMBER_OF_ENABLED_SELF_SCHEDULING_WEB_CODE_LOCATIONS'
    numberOfEnabledLocations: number
    selfSchedulingWebCodeId: string
}

export function setNumberOfEnabledSelfSchedulingWebCodeLocations(
    numberOfEnabledLocations: number,
    selfSchedulingWebCodeId: string,
): SetNumberOfEnabledSelfSchedulingWebCodeLocations {
    return {
        type: 'SET_NUMBER_OF_ENABLED_SELF_SCHEDULING_WEB_CODE_LOCATIONS',
        numberOfEnabledLocations,
        selfSchedulingWebCodeId,
    }
}

export function setAllRSVPsEnabled(webCodeId: string, enabled: boolean): SetAllRSVPsEnabled {
    return {
        type: 'SET_ALL_RSVPS_ENABLED',
        webCodeId,
        enabled,
    }
}

export function receiveRSVPEnabledLocations(
    practiceId: string,
    enabledLocations: HeartbeatAgentLocation[],
): ReceiveRSVPEnabledLocations {
    return {
        type: 'RECEIVE_RSVP_ENABLED_LOCATIONS',
        practiceId,
        enabledLocations,
    }
}

export function setWebCodeLocationsRSVPState({
    active,
    practiceId,
    webCodeId,
    updates,
}: {
    active: boolean
    practiceId: string
    webCodeId: string
    updates: Models.RSVPConnectedLocation[]
}): SetWebCodeLocationsRSVPState {
    return {
        type: 'SET_WEB_CODE_LOCATIONS_RSVP_STATE',
        active,
        practiceId,
        webCodeId,
        updates,
    }
}

export function fetchSelfSchedulingWebCodeList(practiceId: string, params: { search: string; page: number }): any {
    return async (dispatch: Dispatch<ReceiveSelfSchedulingWebCodeList>) => {
        try {
            const { webCodes, metadata } = await ApiV2.SelfScheduling.getSelfSchedulingWebCodes(practiceId, params)
            await dispatch(receiveSelfSchedulingWebCodeList(practiceId, webCodes))
            return metadata
        } catch (e) {
            return e
        }
    }
}

export function softDeleteSelfSchedulingWebCode(
    practiceId: string,
    webCodeId: string,
    searchPage: number,
    searchValue: string,
): any {
    return async (dispatch: any) => {
        try {
            await ApiV2.SelfScheduling.deleteWebCode(webCodeId)
            await dispatch(fetchSelfSchedulingWebCodeList(practiceId, { search: searchValue, page: searchPage }))
        } catch (e) {
            return e
        }
    }
}

export function fetchSelfSchedulingWebCode(id: string): any {
    return async (dispatch: Dispatch<ReceiveSelfSchedulingWebCode>) => {
        try {
            const webCode = await Api.SelfScheduling.getWebCodeById(id)
            await dispatch(receiveSelfSchedulingWebCode(webCode))
            return webCode
        } catch (e) {
            return e
        }
    }
}

export function createSelfSchedulingWebCode(createWebCode: Api.SelfScheduling.WebCodeCreate): any {
    return async (dispatch: Dispatch<ReceiveSelfSchedulingWebCode>) => {
        try {
            const webCode = await Api.SelfScheduling.createWebCode(createWebCode)
            await dispatch(receiveSelfSchedulingWebCode(webCode))
            return webCode
        } catch (e) {
            return e
        }
    }
}

export function saveSelfSchedulingWebCode(updateWebCode: Api.SelfScheduling.WebCodeUpdate): any {
    return async (dispatch: Dispatch<ReceiveSelfSchedulingWebCode>) => {
        try {
            const webCode = await Api.SelfScheduling.updateWebCode(updateWebCode)
            await dispatch(receiveSelfSchedulingWebCode(webCode))
            return webCode
        } catch (e) {
            return e
        }
    }
}

export function searchSelfSchedulingWebCodesLocations(
    practiceId: string,
    webCodeId: string,
    searchTerms: Api.SelfScheduling.WebCodeLocationsSearchTerms,
): any {
    return async (dispatch: Dispatch<ReceiveSelfSchedulingWebCodeLocationsList>) => {
        try {
            const { page } = searchTerms
            const { data: locations, paginationInfo } = await Api.SelfScheduling.searchLocationsByName(
                practiceId,
                webCodeId,
                searchTerms,
            )

            await dispatch(
                receiveSelfSchedulingWebCodeLocationsList({ practiceId, webCodeId, locations, paginationInfo, page }),
            )
            return locations
        } catch (e) {
            return e
        }
    }
}

export function saveSelfSchedulingWebCodeLocation(updateLocation: Api.SelfScheduling.WebCodeLocationUpdate): any {
    return async (dispatch: Dispatch<ReceiveSelfSchedulingWebCodeLocation>) => {
        try {
            const location = await Api.SelfScheduling.updateLocation(updateLocation)
            await dispatch(receiveSelfSchedulingWebCodeLocation(location))
            return location
        } catch (e) {
            return e
        }
    }
}

export function toggleEnableAllSelfSchedulingWebCodeLocations(
    locationToggleEnableAll: Api.SelfScheduling.WebCodeLocationToggleEnableAll,
    searchTerms: Api.SelfScheduling.WebCodeLocationsSearchTerms,
): any {
    return async (dispatch: Dispatch<ReceiveSelfSchedulingWebCodeLocationsList>) => {
        try {
            const { practiceId, webCodeId } = locationToggleEnableAll
            await Api.SelfScheduling.toggleEnableAllLocations(locationToggleEnableAll)
            const { data: locations, paginationInfo } = await Api.SelfScheduling.searchLocationsByName(
                practiceId,
                webCodeId,
                searchTerms,
            )
            await dispatch(
                receiveSelfSchedulingWebCodeLocationsList({
                    practiceId,
                    webCodeId,
                    locations,
                    paginationInfo,
                }),
            )
            return locations
        } catch (e) {
            return e
        }
    }
}

export function fetchNumberOfEnabledSelfSchedulingWebCodeLocations(webCodeId: string): any {
    return async (dispatch: any) => {
        try {
            const numberOfEnabledLocations = await Api.SelfScheduling.countEnabledLocations(webCodeId)
            await dispatch(setNumberOfEnabledSelfSchedulingWebCodeLocations(numberOfEnabledLocations, webCodeId))
            return numberOfEnabledLocations
        } catch (e) {
            return e
        }
    }
}

export function getRSVPEnabledLocations(practiceId: string): any {
    return async (dispatch: any) => {
        try {
            const enabledLocations = await Api.Heartbeat.getRSVPEnabledLocations(practiceId)
            await dispatch(receiveRSVPEnabledLocations(practiceId, enabledLocations))
            return enabledLocations
        } catch (e) {
            return e
        }
    }
}

export function updateLocationRSVPConnection({
    active,
    practiceId,
    webCodeId,
    connectLocationData,
}: {
    active: boolean
    practiceId: string
    webCodeId: string
    connectLocationData: Api.RSVP.ConnectLocationData[]
}): any {
    return async (dispatch: any) => {
        try {
            const updates = await Api.RSVP.putConnectLocation(connectLocationData)
            if (updates) {
                await dispatch(setWebCodeLocationsRSVPState({ active, practiceId, webCodeId, updates }))
            }
            return updates
        } catch (e) {
            return e
        }
    }
}
