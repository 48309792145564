import moment from 'moment'

const API_DATE_FORMAT = 'YYYY-MM-DD'
const DATE_FORMAT = 'MM/DD/YYYY'

export const APP_ERROR_MESSAGE_TIMEOUT = `This appointment has not been scheduled yet. We’ll send an email to the patient as soon as the appointment is scheduled!`
export const PMS_WRITEBACK_DISABLED_MESSAGE = `Because the practice has disabled writeback, this appointment has been requested, not scheduled. We will send an email to the patient as soon as the appointment is scheduled.`

export const formatDateOfBirth = (dob: string) => {
    const isApiFormatType = dob.includes('-')

    if (isApiFormatType) {
        return moment(dob, API_DATE_FORMAT).format(DATE_FORMAT)
    }

    return dob
}
